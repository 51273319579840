import React from 'react';

import { connectRefinementList } from 'react-instantsearch-dom';
import { Card } from 'react-bootstrap';
import { sizeLocalStorageSource, sizeOptions } from '../API/user-preferences'
import ButtonGroupFilter from './button-group-filter';

const localStorage = window.localStorage;

const topRowOptions = sizeOptions.slice(0, 3);
const bottomRowOptions = sizeOptions.slice(3);

const RefinementList = ({
  refine,
  currentRefinement,
  visible,
  title
}) => {
  if (!visible) {
    return null;
  }

  const toHighlight = currentRefinement.length > 0 ? currentRefinement[0] : 'ALL';
  return (
    <Card className="refinement_card">
      <div className="refinement_header">
        {title}
      </div>

      <ButtonGroupFilter
        label="Size"
        options={topRowOptions}
        selectedCallback={(selected) => {
          refine(selected && selected !== 'ALL' ? [ selected ] : []);
          localStorage.setItem(sizeLocalStorageSource, selected);
        }}
        selectedColor={'primary'}
        curSelected={toHighlight}
      />
      <ButtonGroupFilter
        label="Size"
        options={bottomRowOptions}
        selectedCallback={(selected) => {
          refine(selected && selected !== 'ALL' ? [ selected ] : []);
          localStorage.setItem(sizeLocalStorageSource, selected);
        }}
        selectedColor={'primary'}
        curSelected={toHighlight}
      />
      <div className="mt-3" />
    </Card>
  )
};

const SizeRefinementRadio = connectRefinementList(RefinementList);

export default SizeRefinementRadio;
