import React from 'react';
import { Table } from 'react-bootstrap';
import algoliasearch from 'algoliasearch/lite';
import { Col, Row, Button } from 'react-bootstrap';

import {
  InstantSearch,
  SearchBox,
  Stats,
}
from 'react-instantsearch-dom';
import { algoliaIndex } from '../constants';
import AdminProductHits from '../Components/admin-product-hits';
import { addNewProduct } from '../API/admin';
import InputProductRow from '../Components/product-row-input'

const searchClient = algoliasearch('K8XMJXSBFA', '6ac69186071ace2b3f6f2ab74d5f0adb');

const col = [
  'name',
  'id',
  'type',
  'compatibility',
  'link',
]

const defaultNewProductInfo = {
  name: '',
  type: '',
  compatibility: '',
  link: '',
}

const AdminProductPage = () => {
  String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
  }

  const apiKeyFromLocalStorage = window.localStorage.getItem('keyboard-partpicker-api-key');
  const type = 'all'

  const [refresh, setRefresh] = React.useState(false);
  const setRefreshFalse = () => setRefresh(false);

  const refreshCache = () => {
    setRefresh(true);
    setRefreshFalse();
  }

  /// new product information
  const [showNewProductInput, setNewProductInputVisibility] = React.useState(false);
  const [newProductInformation, setNewProductInformation] = React.useState(defaultNewProductInfo)

  const resetNewProductInput = () => {
    setNewProductInputVisibility(!showNewProductInput)
    setNewProductInformation(defaultNewProductInfo)
  }

  const handleAddNewProduct = async() => {
    try {
      const newProduct = await addNewProduct(newProductInformation, apiKeyFromLocalStorage);
      if (newProduct && newProduct.id) {
        setNewProductInputVisibility(false)
        setNewProductInformation(defaultNewProductInfo)
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <br />
      <Button variant={showNewProductInput ? 'danger' : 'success'} onClick={() => resetNewProductInput()}>{`${showNewProductInput ? 'Cancel' : 'Add'} New Product`}</Button>
      <InstantSearch searchClient={searchClient} indexName={algoliaIndex} refresh={refresh}  >
        <Row>
          <Col sm={4}></Col>
          <Col sm={4}>
              <span className="search_header">{ type === 'all' ? 'Search your products' : `Search for your ${type}`}</span>
          </Col>
          <Col sm={4}>
              <span className="search_bar_container">
                  <SearchBox searchAsYouType={false} showLoadingIndicator />
                  <Stats />
              </span>
          </Col>
        </Row>
        <br />
        <Row>
          <Table striped bordered hover>
            <thead>
            <tr>
              {col.map( (el, i) => {
                return (<th key={`${i}asdfa`}>{el.capitalizeFirstLetter()}</th>)
              })}
              <th key={`asdfaa`}>actions</th>
            </tr>
          </thead>
          <tbody>
            {
              showNewProductInput === true && (
              <tr>
                <InputProductRow key={'asdfasdf1234'} productInformationProps={newProductInformation} setProductInformationProps={setNewProductInformation}/>
                <td>
                  <Button variant="success" onClick={() => handleAddNewProduct()}> Save </Button>
                </td>
              </tr>
              )
            }
            <AdminProductHits refreshCache={refreshCache} />
            </tbody>
          </Table >
        </Row>
      </InstantSearch>
    </React.Fragment>
  )
}

export default AdminProductPage;
