import React from "react";
import { withRouter } from "react-router-dom";

import { Col, Row, Container, Accordion, Card } from "react-bootstrap";

import ScrollToTopOnMount from "../Components/scroll-to-top-mount";

import { Helmet } from "react-helmet";

const FAQ = () => {
    return (
        <>
            <Helmet>
                <title>FAQ | Keyboard Part Picker</title>
                <meta
                    name="description"
                    content="Need help with your mechanical keyboard build? Our FAQ page has answers to common questions about building, parts, and more. Find the information you need to make your build a success."
                />
                <meta
                    property="og:title"
                    content="FAQ | Keyboard Part Picker"
                />
                <meta
                    property="og:description"
                    content="Need help with your mechanical keyboard build? Our FAQ page has answers to common questions about building, parts, and more. Find the information you need to make your build a success."
                />
                <meta
                    name="twitter:title"
                    content="FAQ | Keyboard Part Picker"
                />
                <meta
                    name="twitter:description"
                    content="Need help with your mechanical keyboard build? Our FAQ page has answers to common questions about building, parts, and more. Find the information you need to make your build a success."
                />
            </Helmet>
            <Container fluid className="main">
                <ScrollToTopOnMount />
                <Row className="main_header">
                    <Col sm={4}></Col>
                    <Col sm={4}>
                        <h1>Mechanical Keyboard FAQs</h1>
                    </Col>
                    <Col sm={4}></Col>
                </Row>
                <Row>
                    <Col lg={2}></Col>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What is a mechanical keyboard and why should
                                    I build one?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        A mechanical keyboard is a type of
                                        computer keyboard that uses mechanical
                                        switches instead of the traditional
                                        rubber dome switches found in most
                                        traditional keyboards. Mechanical
                                        keyboards are known for their
                                        durability, precision, and satisfying
                                        tactile feedback, which makes them
                                        popular among gamers and typists.
                                        Building a mechanical keyboard allows
                                        you to customize the look, feel, and
                                        functionality of your keyboard to your
                                        personal preferences.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    What do I need to build a mechanical
                                    keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        Here is a list of the basic components
                                        you will need to build a mechanical
                                        keyboard:
                                        <ul>
                                            <li>
                                                A PCB (printed circuit board) to
                                                connect all of the components
                                                and provide the necessary
                                                support for the switches and
                                                keycaps.
                                            </li>
                                            <li>
                                                Switches to register key
                                                presses.
                                            </li>
                                            <li>
                                                Keycaps to cover the switches
                                                and identify the keys.
                                            </li>
                                            <li>
                                                A case to hold the PCB and
                                                switches in place and provide a
                                                base for the keycaps.
                                            </li>
                                            <li>
                                                Stabilizers to keep larger keys,
                                                such as the space bar, from
                                                wobbling.
                                            </li>
                                            <li>
                                                A microcontroller to process
                                                input and send signals to the
                                                computer.
                                            </li>
                                            <li>
                                                A USB cable to connect the
                                                keyboard to the computer.
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    How do I choose the right switches for my
                                    mechanical keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        There are many different types of
                                        mechanical switches available, each with
                                        its own unique characteristics. The most
                                        important factor to consider when
                                        choosing switches is the type of
                                        feedback you prefer. Some switches have
                                        a linear, smooth feel, while others have
                                        a more tactile, bumpy feel. Some
                                        switches are also louder than others.
                                        It&apos;s a good idea to try out a few
                                        different types of switches to see which
                                        ones you like best. You can also refer
                                        to online reviews and switch testing
                                        videos to get a better understanding of
                                        the feel of different switches.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    How do I choose the right keycaps for my
                                    mechanical keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        Keycaps come in a variety of materials,
                                        colors, and profiles. The material of
                                        the keycaps can affect the feel and
                                        sound of the keyboard, with options such
                                        as ABS plastic, PBT plastic, and metal
                                        alloys. The color of the keycaps can be
                                        chosen to match your personal style or
                                        the theme of your keyboard. The profile
                                        of the keycaps refers to the shape and
                                        height of the keys, with options such as
                                        OEM, Cherry, and DSA. It&apos;s a good
                                        idea to try out a few different types of
                                        keycaps to see which ones you like best.
                                        You can also refer to online reviews and
                                        keycap testing videos to get a better
                                        understanding of the feel and sound of
                                        different keycaps.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    How do I choose the right case for my
                                    mechanical keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        There are many different types of cases
                                        available for mechanical keyboards,
                                        ranging from simple plastic cases to
                                        elaborate aluminum cases. The most
                                        important factor to consider when
                                        choosing a case is the size and layout
                                        of the keyboard. You&apos;ll also want
                                        to consider the material of the case and
                                        whether you want a case with a built-in
                                        plate or one that requires a separate
                                        plate. It&apos;s a good idea to measure
                                        your PCB and switches to make sure they
                                        will fit in the case you choose. You
                                        should also consider the overall
                                        aesthetic of the case and whether it
                                        fits your personal style.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                    How do I choose the right size and layout
                                    for my keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        The size and layout of a keyboard can
                                        greatly affect your typing experience.
                                        Full-size keyboards have a traditional
                                        layout with a numeric keypad, while
                                        tenkeyless (TKL) keyboards omit the
                                        numeric keypad for a more compact
                                        design. There are also even smaller
                                        options like 60% and 75% keyboards,
                                        which omit the function row and some of
                                        the keys on the right side of the
                                        keyboard. It&apos;s important to
                                        consider the size and layout that will
                                        work best for you based on your typing
                                        needs and the amount of space you have
                                        available on your desk.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="6">
                                    What are stabilizers and do I need them?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>
                                        Stabilizers are mechanical components
                                        that are used to evenly distribute the
                                        force applied to a key when it is
                                        pressed. They help prevent keys from
                                        feeling loose or wobbly and can improve
                                        the overall typing experience.
                                        Stabilizers are typically used on larger
                                        keys, such as the space bar and shift
                                        keys, and are often included with a
                                        mechanical keyboard. However, some
                                        keyboards do not come with stabilizers,
                                        or the stabilizers may be of lower
                                        quality, in which case you may want to
                                        consider purchasing aftermarket
                                        stabilizers to improve the feel of your
                                        keyboard.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                    How do I assemble my mechanical keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="7">
                                    <Card.Body>
                                        Assembling a mechanical keyboard
                                        involves a few basic steps:
                                        <ol>
                                            <li>
                                                Begin by soldering the switches
                                                to the PCB. If you are using
                                                through-hole switches, you will
                                                need to insert the leads through
                                                the holes on the PCB and then
                                                solder them in place. If you are
                                                using surface-mount switches,
                                                you will need to place the
                                                switches on the PCB and then
                                                solder them in place.
                                            </li>
                                            <li>
                                                Next, install the stabilizers
                                                for the larger keys. This
                                                involves attaching the
                                                stabilizers to the PCB and then
                                                attaching the keycaps to the
                                                stabilizers.
                                            </li>
                                            <li>
                                                Once the switches and
                                                stabilizers are in place, you
                                                can install the PCB in the case
                                                and attach the keycaps to the
                                                switches.
                                            </li>
                                            <li>
                                                Finally, you will need to
                                                install the microcontroller and
                                                connect the keyboard to your
                                                computer using the USB cable.
                                            </li>
                                        </ol>
                                        <p>
                                            It&apos;s a good idea to refer to a
                                            detailed guide or video tutorial for
                                            specific instructions on how to
                                            assemble your particular keyboard.
                                            It&apos;s also a good idea to have a
                                            basic understanding of soldering and
                                            electronics before attempting to
                                            build a mechanical keyboard.
                                        </p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                    Can I customize the firmware on my
                                    mechanical keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="8">
                                    <Card.Body>
                                        Yes, many mechanical keyboards allow you
                                        to customize the firmware to change the
                                        behavior of the keyboard. For example,
                                        you can change the layout of the keys,
                                        program macros, or customize the
                                        lighting effects. To customize the
                                        firmware on your mechanical keyboard,
                                        you will need to install a firmware such
                                        as the popular open-source firmware QMK.
                                        You will also need a programmer, such as
                                        a USBasp or a ST-Link, to flash the
                                        firmware to the microcontroller on your
                                        keyboard. It&apos;s a good idea to refer
                                        to the documentation for your particular
                                        keyboard and firmware for specific
                                        instructions on how to customize the
                                        firmware.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="9">
                                    Can I customize the lighting on my
                                    mechanical keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="9">
                                    <Card.Body>
                                        Many mechanical keyboards come with
                                        customizable LED lighting, which can add
                                        a visually striking element to your
                                        setup. Some keyboards allow you to
                                        choose from a range of static colors,
                                        while others offer more advanced
                                        lighting effects like rainbow waves or
                                        key-by-key customization. If you&apos;re
                                        interested in customizing the lighting
                                        on your keyboard, make sure to choose a
                                        keyboard with a lighting feature that
                                        meets your needs.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="10"
                                >
                                    How do I clean and maintain my mechanical
                                    keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="10">
                                    <Card.Body>
                                        To clean your mechanical keyboard, you
                                        can use a soft cloth or brush to remove
                                        any dirt or debris from the keycaps and
                                        case. You can also use a compressed air
                                        duster to blow away any debris that is
                                        stuck in between the keycaps. To remove
                                        grime or dirt that is stuck to the
                                        keycaps or case, you can use a mild
                                        cleaning solution and a soft cloth.
                                        Avoid using harsh chemicals or abrasive
                                        cleaners, as they can damage the
                                        keyboard.
                                        <br />
                                        To maintain your mechanical keyboard,
                                        you should regularly lubricate the
                                        switches and stabilizers to ensure
                                        smooth and consistent performance. You
                                        should also periodically replace the
                                        keycaps and switches if they become worn
                                        or damaged. It&apos;s a good idea to
                                        refer to the documentation for your
                                        particular keyboard for specific
                                        maintenance recommendations.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="11"
                                >
                                    Can I use a wireless mechanical keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="11">
                                    <Card.Body>
                                        Yes, there are several options for
                                        wireless mechanical keyboards on the
                                        market. These keyboards typically use
                                        Bluetooth or a proprietary wireless
                                        connection to connect to your computer
                                        or device. Some wireless mechanical
                                        keyboards also come with a wired option
                                        for when you need a more stable
                                        connection. Keep in mind that wireless
                                        keyboards may have a slightly slower
                                        response time compared to wired
                                        keyboards due to the nature of wireless
                                        communication.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="12"
                                >
                                    How much does it cost to build a custom
                                    mechanical keyboard?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="12">
                                    <Card.Body>
                                        The cost of building a custom mechanical
                                        keyboard can vary widely depending on
                                        the parts and features you choose. A
                                        basic keyboard with standard switches
                                        and keycaps can cost as little as $100,
                                        while a more advanced keyboard with
                                        custom switches, keycaps, and lighting
                                        can cost several hundred dollars or
                                        more. It&apos;s a good idea to set a
                                        budget and do some research to find the
                                        best combination of parts and features
                                        that meet your needs and fit your
                                        budget.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                    <Col lg={2}></Col>
                </Row>
            </Container>
        </>
    );
};

export default withRouter(FAQ);
