import { backendUrl, publicApiKey } from '../constants';
import { addToHistory } from './history';
import { getErrorMessage } from './common';
import axios from 'axios';

const axiosOptions = {
  headers: {
    'x-api-key': publicApiKey
  }
};

async function createBuildLink(products) {
  return axios.post(`${backendUrl}/build`, {
    products: products,
  }, axiosOptions).then((response) => {
    return { link: `/build/${response.data.linkId}` };
  }).catch((err) => {
    return { message: getErrorMessage(err) };
  })
}

export const getInitialProducts = async(linkId) => {
  return axios.get(`${backendUrl}/build/${linkId}`, axiosOptions).then((response) => {
    if (response.data && response.data.products && response.data.products.length > 0) {
      addToHistory({ action: 'view', linkId: `/build/${linkId}` });
    }
    return {
      products: response.data.products,
      message: response.status === 206 ? "Not all products were found for your build.  This usually implies the vendor has removed them from their inventory.  We are sorry for the inconvenience." : null,
    };
  }).catch((err) => {
    return {
      products: [],
      message: getErrorMessage(err),
    };
  });
}

export const addProduct = async(curProducts, product, replaceSameType = false, allowDuplicates = true, quantity = null) => {
  if (!curProducts) { curProducts = []; }
  if (replaceSameType) {
    curProducts = curProducts.filter((cur) => {
      return cur.type !== product.type;
    });
  }

  if (allowDuplicates) {
    if (curProducts && curProducts.length > 0) {
      let duplicateProduct = curProducts.find(p => p.id === product.id);
      if (duplicateProduct) {
        duplicateProduct.quantity = quantity ? quantity : duplicateProduct.quantity + 1;
      }
      else {
        product.quantity = quantity ? quantity : 1
        curProducts.push(product)
      }
    }
    else {
      product.quantity = 1
      curProducts.push(product)
    }
  }

  const result = await createBuildLink(curProducts);
  if (!quantity) {
    addToHistory({ resource: product.name, action: 'add', linkId: result.link });
  }
  else {
    addToHistory({ resource: product.name, action: 'change-quantity', linkId: result.link });
  }
  return result;
}

export const removeProduct = async(curProducts, product) => {
  const products = curProducts.filter((p) => { return p.id !== product.id })
  if (!products || products.length <= 0) {
    // If there are no more products just send them to home page.
    return { link: '/' };
  }
  else {
    const result = await createBuildLink(products);
    addToHistory({ resource: product.name, action: 'remove', linkId: result.link });
    return result;
  }
}

export const resetProducts = async(store) => {
  store.setState({
    linkId: null,
    products: []
  });
  return ``;
}