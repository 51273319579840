import React, { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch';
import { algoliaAppId, algoliaApiKey, algoliaIndex, RecommendationsIDs } from '../constants';
import ProductRow from './product-row';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card } from 'react-bootstrap';

const frontendSearchClient = algoliasearch(algoliaAppId, algoliaApiKey);
const index = frontendSearchClient.initIndex(algoliaIndex);

const ProductRecommendations = ({ history, type, prettyType, mobileMaxWidth }) => {
    const [hits, setHits] = useState(null);
    useEffect(() => {
        const bootstrap = async() => {
            if (!hits && RecommendationsIDs[type]) {
                try {
                    setHits((await index.getObjects(RecommendationsIDs[type])).results.filter(x => x));
                }
                catch (e) {
                    console.error("Cannot Query Algolia For Product Recommendations.");
                    // Don't break page if can't load recs.
                }
            }
        }
        bootstrap();
    }, []);

    if (hits && hits.length > 0) {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: mobileMaxWidth },
                items: 1
            },
            tablet: {
                breakpoint: { max: mobileMaxWidth, min: 768 },
                items: 1
            },
            mobile: {
                breakpoint: { max: 768, min: 0 },
                items: 1,
            }
        };
        const title = prettyType === "Parts" ? "Keyboard Part Picker's Recommendations" : `Keyboard Part Picker's ${prettyType} Recommendations`;
        return (
            <Card className="product-recs hits_card">
                <span style={{fontSize: "var(--small-font-size)"}}>{title}</span>
                <Carousel responsive={responsive}
                    keyBoardControl={true}
                    showDots={hits.length > 1 ? true : false}
                    infinite={hits.length > 1 ? true : false}
                    autoPlay={hits.length > 1 ? true : false}
                    autoPlaySpeed={8000}
                >
                    {hits.map((hit, id) => {
                        return (
                            // TODO: Hook into Insights somehow.
                            <ProductRow key={id} hit={hit} history={history} hideFloatingButtons={true} />
                        );
                    })}
                </Carousel>
            </Card>
        );
    }
    else {
        return null;
    }
};

export default ProductRecommendations;
