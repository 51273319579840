export const compatibilityTypes = {
  'sizeCompatibility': {
    title: 'Ensure That Your Parts Are the Same Size',
    description: 'All of the PCBs, plates, and cases you have selected should be the same size. Make sure that your parts are of the same size before proceeding with your build.'
  },
  'profileCompatibility': {
    title: 'Keycap Profiles Should Be the Same',
    description: 'The keycaps you have chosen are not of the same profile. Keycap profiles determine the height and sculpting of keys, and having varying keycap profiles may not feel or look good. Make sure that your keycaps are of the same profile before proceeding with your build.'
  },
  'switchCompatibility': {
    title: 'Use the Same Type of Switches',
    description: 'The switches you have chosen are not of the same type. Using varying switches may provide an inconsistent feel and sound. Make sure that your switches are of the same type before proceeding with your build.'
  },
  'proprietaryCompatibility': {
    title: 'Verify Compatibility Between Cases, PCBs, and Plates',
    description: `To ensure that your new keyboard build is successful, it's important to choose cases, PCBs, and plates that are compatible with each other. If you're not sure whether your parts will work together, you can check the product pages or ask for help from the community.`
  },
  'pinCompatibility': {
    title: 'Ensure That Your Switches and PCB Are Pin-Compatible',
    description: 'In order to attach switches to a PCB, the pins of the switches must be compatible with the PCB. There are two standard pin types for switches, 3-pin and 5-pin. Make sure that your switches and PCB are pin-compatible before proceeding with your build. You can find more information on the product pages or by asking for help from the community.',
    productTypeSpecifics: {
      switch: {
        title: 'Selected switches must have less pins than your PCB supports'
      },
      pcb: {
        title: 'Selected PCB must contain enough pins to accommodate your selected switches'
      },
      kit: {
        title: 'Selected Kit must contain a PCB that has enough pins to accommodate selected switches'
      }
    }
  },
  'keycountCompatibility': {
    title: 'Make Sure You Have Enough Switches and Keycaps for Your Build',
    description: 'For each switch socket on your PCB, you will need switches and keycaps to fill in the gap. Make sure that the quantity of switches and keycaps in your build will suffice to fit your keyboard.',
    productTypeSpecifics: {
      switch: {
        title: 'Number of switches must be greater than or equal to number of slots your selected PCB can hold'
      },
      cap: {
        title: 'Number of keycaps must be greater than or equal to number of slots your selected PCB can hold'
      }
    }
  },
  'portCompatibility': {
    title: 'Ensure That Your Case and PCB Have Matching Port Locations',
    description: 'Most PCBs have a single location for a port where a cable may be plugged in to provide power to your keyboard. All PCBs are situated within a case, so cases must have their port at the same location on the board as the PCB. Make sure that your case and PCB have matching port locations before proceeding with your build.'
  },
  'largeKeysCompatibility': {
    title: 'Ensure That Your PCB and Stabilizers Support Your Keycaps',
    description: 'Stabilizers are utilized to help larger keycaps bind without wobble to a switch. There are varying sizes in stabilizers and keycaps, and your selected stabilizer and larger keycaps must be compatible in size. Make sure that your PCB and plate support your selected stabilizers before proceeding with your build.',
    productTypeSpecifics: {
      pcb: {
        title: 'Ensure your PCB is compatible with selected Stabilizer and Keycaps'
      },
      stabilizer: {
        title: 'Ensure that your Keycaps are Compatible with your Stabilizers.'
      },
      cap: {
        title: 'Ensure That Your Keycaps Are Compatible with Your Stabilizers'
      }
    }
  }

  // commented for now, see comment in API compat file
  /*'formatCompatibility': {
    title: '',
    description: 'Verify that all of the products you have selected have the same form factor. The relevant products are listed below.'
  },*/
};

export const getCompatibilityTitle = (compatibilityType, productType) => {
  const info = compatibilityTypes[compatibilityType];
  if (info['productTypeSpecifics'] && info['productTypeSpecifics'][productType] && info['productTypeSpecifics'][productType]['title']) {
    return info['productTypeSpecifics'][productType]['title'];
  }
  else {
    return info.title;
  }
}

export const getCompatibilityDescription = (compatibilityType, productType) => {
  const info = compatibilityTypes[compatibilityType];
  if (info['productTypeSpecifics'] && info['productTypeSpecifics'][productType] && info['productTypeSpecifics'][productType]['description']) {
    return info['productTypeSpecifics'][productType]['description'];
  }
  else {
    return info.description;
  }
}
