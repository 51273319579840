import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import ConfirmButton from './confirm-button';
import { Button, FormControl, Modal, InputGroup, Tab, Tabs, Row, Col } from 'react-bootstrap'

const ShareModal = ({ showShareModal, setShowShareModal, getBuildAsText, getBuildAsMd, copyToClipboard}) => {
  const [tab, setTab] = useState('text');

  let code;
  if (showShareModal) {
    if (tab == "markdown") {
      code = getBuildAsMd();
    } else {
      code = getBuildAsText();
    }
  }
  return (
    <Modal show={showShareModal} dialogClassName="modal-custom">
      <Modal.Header closeButton onClick={() => { setShowShareModal(false) }}>
        <Modal.Title>Copy Your Build And Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="text"
          activeKey={tab}
          onSelect={(k) => setTab(k)}>
          <Tab eventKey="text" title="Plain Text">
            <InputGroup size="lg">
              <FormControl rows={10} as="textarea" aria-label="With textarea" value={code} plaintext readOnly />
            </InputGroup>
          </Tab>
          <Tab eventKey="markdown" title="Markdown">
            <Row>
              <Col sm={6}>
                <InputGroup size="lg">
                  <FormControl rows={10} as="textarea" aria-label="With textarea" value={code} plaintext readOnly />
                </InputGroup>
              </Col>
              <Col sm={6}>
                <ReactMarkdown plugins={[gfm]}>
                  {code}
                </ReactMarkdown>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { setShowShareModal(false) }}>Close</Button>
        <ConfirmButton variant="primary" onClick={() => { copyToClipboard(code) }} confirmtext={' Copied'}>Copy</ConfirmButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareModal
