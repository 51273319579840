import React from 'react';
import { FormControl, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  checkAPIkey
}
from '../API/admin';

const invalidApiKeyMessage = () => toast.error('🦄 Please enter a valid API key', {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const ProtectedRoute = ({
  component
}) => {
  const apiKeyFromLocalStorage = window.localStorage.getItem('keyboard-partpicker-api-key');
  const [isAuthenticated, setAuthenticationStatus] = React.useState(false);

  React.useEffect(() => {
    if (apiKeyFromLocalStorage) {
      const fetchAuthenticationStatus = async() => {
        await checkAPIkey(apiKeyFromLocalStorage, setAuthenticationStatus);
      }
      fetchAuthenticationStatus();
    }
  }, [apiKeyFromLocalStorage])

  const Component = component;

  const [keyState, setKeyState] = React.useState('');
  const handleClick = async() => {
    try {
      window.localStorage.setItem('keyboard-partpicker-api-key', keyState)
      await checkAPIkey(keyState, setAuthenticationStatus);
    }
    catch (error) {
      console.log(error);
      invalidApiKeyMessage();
    }
  }

  if (isAuthenticated) {
    return <Component />
  }
  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <FormControl onChange={(e) => { setKeyState(e.target.value) }} value={keyState} />
      <Button onClick={handleClick}>Submit Key</Button>
    </React.Fragment>
  )
}

export default ProtectedRoute;
