import React, { useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

// options expects an array of objects with at minimum: { label, value }
const ButtonGroupFilter = ({ options, defaultSelection, label, selectedCallback, fillHeight, showImage, selectedColor, curSelected, fontSize }) => {
  if (!fontSize) {
    fontSize = '14px';
  }
  let defaultSelected = null;
  defaultSelected = defaultSelection ? defaultSelection : defaultSelected;
  const [selected, setSelected] = useState(defaultSelected)

  const selectedToUse = curSelected ? curSelected : selected;

  return (
    <ButtonGroup size="sm" className="button-group-filter" style={ fillHeight ? { height: '100%' } : {}}>
      {
        options.map( (option) => {
          const selectedStyle = selectedToUse === option.value ? {
            opacity: 1,
            fontWeight: 'bold'
          } : {
            opacity: .6,
            fontWeight: 'bold',
          }
          return (
              <Button
                key={`label-${option.value}-${label}`}
                variant='secondary'
                onClick={() => {
                  if (selectedCallback) {
                    selectedCallback(option.value);
                  }
                  setSelected(option.value);
                }}
                style={{ ...selectedStyle, fontSize: fontSize, height: fillHeight ? '100%' : '40px', paddingTop: '8px', paddingBottom: '8px' }}
                className={ selectedColor === 'primary' && selectedToUse === option.value ? `bg-primary text-light` : `` }
              >
                <b style={{backgroundColor:'transparent'}}>{option.label}</b>
                {
                  showImage && option.img ?
                    (
                      <React.Fragment>
                        <br />
                        <img style={{ paddingTop: '6px', width: '40px' }} src={option.img} />
                      </React.Fragment>
                    ) :
                    null
                }
              </Button>
          )
        })
      }
    </ButtonGroup>
  )
}

export default ButtonGroupFilter;
