import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { Button } from 'react-bootstrap';

import InputProductRow from '../Components/product-row-input'

import { updateAdminProduct, deleteProductAdmin } from '../API/admin';

export const AdminProductHit = ({
  productInformation,
  refreshCache,
}) => {
  const apiKeyFromLocalStorage = window.localStorage.getItem('keyboard-partpicker-api-key');

  const [isDeleted, setDeleted] = React.useState(false);
  const [isEditMode, setEditMode] = React.useState(false);
  const [productInformationProps, setProductInformationProps] = React.useState(productInformation)
  const { name, type, compatibility, link, id } = productInformationProps;

  const handleUpdate = async() => {
    try {
      const returnedPostedProduct = await updateAdminProduct(productInformationProps, apiKeyFromLocalStorage);
      console.log(returnedPostedProduct, isEditMode)
      if (returnedPostedProduct) {
        setEditMode(false);
        refreshCache();
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleDelete = async(id) => {
    try {
      await deleteProductAdmin(id, setDeleted, apiKeyFromLocalStorage)
      refreshCache();
    }
    catch (error) {
      console.log(error);
    }
  }

  if (isDeleted) {
    return null;
  }

  return (
    <tr>
      {
        isEditMode === true && (
        <InputProductRow productInformationProps={productInformationProps} setProductInformationProps={setProductInformationProps}/>
        )
      }
      {
        isEditMode === false && (
        <React.Fragment>
          <td>{name}</td>
          <td>{id}</td>
          <td>{type}</td>
          <td>{compatibility}</td>
          <td>{link}</td>
        </React.Fragment>
        )
      }
      <td>
        <Button variant="primary" onClick={isEditMode ? () => handleUpdate() : () => setEditMode(true)}>Edit</Button>
        <br />
        <Button variant="danger" onClick={() => handleDelete(id) }>Delete</Button>
      </td>
    </tr>
  )
}

const AdminProductHits = ({ hits, refreshCache }) => hits.map(hit => {
  return <AdminProductHit productInformation={hit} key={hit.id} refreshCache={refreshCache} />
})

const ConnectedAdminProductHits = connectHits(AdminProductHits);

export default ConnectedAdminProductHits;
