// this was mostly taken from here: https://codesandbox.io/s/react-fullscreen-overlay-sbjs4?file=/src/SearchOverlay.jsx

import { Modal, Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import React, { useState } from 'react';
import { shouldHidePrice, isAmazonProduct } from '../API/product';
import { HitName } from './product-row';

const prev = 'previous slide / item'
const next = 'next slide / item'

const Search = props => {
  const { hit, images, onClick, link, variantText, groupBuyText, website } = props;
  const [imageModalVisible, setImageModalVisible] = useState(false);

  const onImageClick = () => {
    // Run onClick if provided.
    if (onClick) {
      onClick();
    }
    setImageModalVisible(true);
    setTimeout(() => {
      const prevButton = document.querySelector(`button[aria-label="${prev}"`)
      const nextButton = document.querySelector(`button[aria-label="${next}"`)

      document.addEventListener('keyup', (e) => {
        if (e.code === 'ArrowLeft' || e.code === 'KeyA') prevButton.click()
        if (e.code === 'ArrowRight' || e.code === 'KeyD') nextButton.click()
        if (e.code === 'ArrowUp') prevButton.click()
        if (e.code === 'ArrowDown') nextButton.click()
      })
    }, 0.25 * 1000)
  }

  const onModalClose = () => {
    setImageModalVisible(false)
  }
  // TODO: Make a default "no pic" jpg
  let featuredImage = null;
  if (images && images.length > 0) {
    featuredImage = images[0];
  }

  const priceText = !shouldHidePrice ? (` | $${(Math.round(parseFloat(hit.price) * 100) / 100).toFixed(2)}`) : null;
  const linkText = isAmazonProduct(hit) ?
    (<span>Sold by Amazon at <a target="_blank" rel="noreferrer" className="hit_title_bold_subtext" href={hit.affiliateLink}>{hit.affiliateLink}</a></span>) :
    (<span>Sold by <a href={link} target="_blank" rel="noreferrer" className="hit_title_bold_subtext">{ website }</a></span>);

  return (
    <React.Fragment>
      <LazyLoadImage className="hit_image" src={featuredImage} effect="blur" onClick={() => {
        onImageClick();
      }}/>
      <Modal show={imageModalVisible} onHide={() => onModalClose()} animation={false}>
        <Modal.Header closeButton onClick={() => { setImageModalVisible(false) }}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <div className="image_carousel">
          <Carousel>
            {
              images ?
                images.map( (image, i) => {
                  return (
                    <div key={i}>
                      <img src={image} />
                    </div>
                  )
                }) :
                null
            }
          </Carousel>
        </div>
        <Modal.Body style={{paddingTop: '0px'}} className="hit_title">
          <a href={link} target="_blank" rel="noreferrer" >
            <b><u><HitName hit={hit} /></u></b>
          </a>
          <div className="hit_title_subtext">
            {groupBuyText}{hit.vendor}{variantText}{priceText}
          </div>
          <div className="hit_title_soldby">
            {linkText}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setImageModalVisible(false) }>Close</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Search;
