import React from 'react';
import { withRouter } from 'react-router-dom'

import { Col, Row, Container } from 'react-bootstrap';

import ScrollToTopOnMount from '../Components/scroll-to-top-mount';

import __html from '../Content/tos.html';
import { Helmet } from 'react-helmet';
const template = { __html: __html };

const ToS = () => {
  return (
    <>
      <Helmet>
        <title>Terms | Keyboard Part Picker</title>
      </Helmet>
      <Container fluid className="main">
        <ScrollToTopOnMount />
        <Row className="main_header">
          <Col sm={4}></Col>
          <Col sm={4}>
            <h1 className="main_text">Terms of Service</h1>
          </Col>
          <Col sm={4}>
          </Col>
        </Row>
        <Row>
          <Col lg={1}></Col>
          <Col>
            <span dangerouslySetInnerHTML={template} />
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </>
  )
}

export default withRouter(ToS);
