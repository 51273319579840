const trackingIds = {
    staging: "UA-186931712-3",
    preprod: "UA-186931712-2",
    prod: "UA-186931712-1",
};

export const isPreProd =
    window.location.host === "preprod.keyboardpartpicker.com";
export const isOnlyProd = window.location.host === "keyboardpartpicker.com";
export const isProd =
    window.location.host === "keyboardpartpicker.com" ||
    window.location.host === "preprod.keyboardpartpicker.com";

export const algoliaIndex = isProd ? "product-prod" : "product-dev";
export const algoliaQuerySuggestionsIndex = isProd
    ? "product-prod_query_suggestions"
    : "product-dev_query_suggestions";
export const algoliaAppId = "K8XMJXSBFA";
export const algoliaApiKey = isProd
    ? "6e77af9d2c2c4310d2927fb8aa1b1b2d"
    : "d32d44b3a4de3fc1203ddea859285dab";

export const backendUrl = isProd
    ? `https://api.keyboardpartpicker.com`
    : `https://api-staging.keyboardpartpicker.com`;
export const adminUrl = isProd
    ? `https://api-admin.keyboardpartpicker.com`
    : `https://api-admin-staging.keyboardpartpicker.com`;
export const trackingId = isOnlyProd
    ? trackingIds.prod
    : isPreProd
    ? trackingIds.preprod
    : trackingIds.staging;
export const publicApiKey = isProd
    ? "j8RPPF7v8t8s3x3o10IQ85hrJyL3wSpZ5bbxVLUi"
    : "D1Wxo9W7QO28uzWsTAyQo9RA6FTIMLnZ3uknyCMk";

export const ProductNameMap = {
    All: "all",
    Prebuilt: "prebuilt",
    "DIY Kit": "kit",
    Case: "case",
    PCB: "pcb",
    Plate: "plate",
    Keycaps: "cap",
    Switches: "switch",
    Stabilizers: "stabilizer",
    Tools: "tools",
    Extras: "extras",
    Deskmats: "deskmat",
    Cables: "cable",
    "Lube Essentials": "lube",
    "Switch Essentials": "switch-essentials",
    "Soldering Essentials": "solder-essentials",
};

const productNameMap = {};
Object.keys(ProductNameMap).forEach((key) => {
    productNameMap[ProductNameMap[key]] = key;
});
export const ProductTypeToNameMap = { ...productNameMap };

export const RecommendationsIDs = isProd
    ? {
          switch: [
              "ee83fb6f-ee75-4643-9fcc-2c21c8b373b1", // tactile
              "f67abd27-20c5-4852-8486-8b040ba0b408", // linear
              "c7287e6e-89bc-4ad3-b297-26aea8cf423a", // tactile
              "f14b5d08-8f7d-4d03-930c-db08c3d7aecb", // linear
              "58d6eaa3-bbbd-436c-a9b7-2ea2d3aa1849", // tactile
              "b88bfead-ea1d-40a2-9036-a8bb86e8e2b1", // linear
              "e850a22e-7d7d-40d1-811d-2dedf4e6eb11", // tactile
              "83ac9015-a77c-4e61-b587-2ffebc1f5ae3", // linear
          ],
      }
    : {
          case: ["c3eee7ea-99cf-49c2-be1b-ff4bd7058d4c"],
          switch: [
              "f7cb713f-e815-4138-a6e6-4c2554fd97f1",
              "f6abced3-a4db-43d0-8a7c-aa84ce5fe5de",
          ],
      };
