import React, { useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { ProductTypeToNameMap } from '../constants';
import { getCompatibilityTitle, getCompatibilityDescription } from '../API/compatibility-information';
import Icon from '@mdi/react';
import { mdiInformation, mdiAlert } from '@mdi/js'
import Collapsible from 'react-collapsible';

const CollapsibleRecommendation = ({ recommendation, defaultOpened, type }) => {
  const compatibilityType = recommendation.compatibilityType;
  const [showDetails, setShowDetails] = useState(recommendation.compatibilityType === defaultOpened);
  return (
    <div className="mb-2">
      <Alert className={`standard-border-radius clickable text-light ${recommendation.isWarning ? 'bg-alert' : 'bg-danger'} mb-0`} style={{paddingLeft: '44px'}} onClick={() => setShowDetails(!showDetails)}>
        <Alert.Heading className="align-middle h5">
          { recommendation.isWarning ?
            <Icon style={{width: '1.21875rem', height: '1.21875rem'}} path={mdiInformation}/>
            :
            <Icon style={{width: '1.21875rem', height: '1.21875rem'}} path={mdiAlert}/>
          }
          { getCompatibilityTitle(compatibilityType, type) }
        </Alert.Heading>
        <p className="mb-0 align-middle">
          <span className="avoidwrap pl-1"> { recommendation.relevantProducts.length } Affected Parts </span>   <span className="avoidwrap pl-1"><u>{ showDetails ? `Hide Details` : `Show Details` }</u></span>
        </p>
      </Alert>
      <Collapsible
          open={showDetails}
          transitionTime={100}
      >
        <div className="padding-medium" style={{ border: '3px solid gray', borderTop: '0px', borderRight: '0px', borderRadius: '50px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', paddingLeft: '44px', paddingTop: '24px' }}>
          { getCompatibilityDescription(compatibilityType, type) }
          <br/>
          <br/>
          Related parts:
          <ul>
          {
            recommendation.relevantProducts.sort((a, b) => {
              if (a.type && b.type) {
                return a.type.localeCompare(b.type);
              }
            }).map((r) => {
              return (
                <li key={r.id}>
                  {ProductTypeToNameMap[r.type]} - <a rel="noreferrer" className="text-dark clickable" target="_blank" href={r.affiliateLink ? r.affiliateLink : r.link}><u>{r.name}</u></a>
                </li>
              )
            })
          }
          </ul>
        </div>
      </Collapsible>
    </div>
  );
}

const RecommendationList = ({ recommendations, header, defaultOpened }) => {
  if (recommendations.length === 0) {
    return null;
  }
  const list = recommendations.map((r) => {
    return (
      <CollapsibleRecommendation key={r.compatibilityType} recommendation={r} defaultOpened={defaultOpened} />
    );
  });
  return (
    <div>
      <div className="padding-medium">
        <div className="large-font-size">
          { header }
        </div>
        { list }
      </div>
    </div>
  );
}

const CompatibilityModal = ({ showModal, setShowModal, dangerRecs, warningRecs, defaultOpened }) => {
  return (
    <Modal show={showModal} dialogClassName="modal-custom" onHide={() => {}}>
      <Modal.Header closeButton onClick={() => setShowModal(false) }>
        <Modal.Title>Compatibility</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          dangerRecs.length > 0 ?
            (
              <div className="text-danger medium-font-size padding-medium"  style={{ paddingLeft: '20px', paddingBottom: '0px' }}>
                <div><b className="text-danger">{dangerRecs.length} { dangerRecs.length > 1 ? 'Incompatibilities' : 'Incompatibility' } Found</b></div>
              </div>
            ) : null
        }
        <RecommendationList recommendations={dangerRecs} header="" defaultOpened={defaultOpened} />


        {
          // One Compatibility Issue Requires Further Verification
          warningRecs.length > 0 ?
            (
              <div className="text-alert medium-font-size padding-medium" style={{ paddingLeft: '20px', paddingBottom: '0px' }}>
                <div><b className="text-alert">{warningRecs.length} Compatibility { warningRecs.length > 1 ? 'Issues Require' : 'Issue Requires' } Further Verification</b></div>
              </div>
            ) : null
        }
        <RecommendationList recommendations={warningRecs} header="" defaultOpened={defaultOpened}/>
        {
          dangerRecs.length === 0 && warningRecs.length === 0 ?
            <div>Add more products to your build to receive compatibility guidance</div> : null
        }
      </Modal.Body>
      <Modal.Footer>
        <div className="text-primary horizontal-center small-font-size">
          <b>Note: KeyboardPartPicker.com does its best to provide accurate compatibility checks, but it cannot guarantee their completeness. Please double-check the compatibility of your parts before building your keyboard.</b>
        </div>
        <Button variant="secondary" onClick={() => setShowModal(false) }>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CompatibilityModal;
