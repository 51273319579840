import React from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { getHistory, clearHistory } from "../API/history";
import Icon from "@mdi/react";
import { mdiOpenInNew } from "@mdi/js";

const HistoryEntry = (entry) => {
    let entryText = null;
    entry = entry.entry;
    if (entry.action === "view") {
        entryText = <span>Viewed build</span>;
    } else if (entry.action === "add") {
        entryText = (
            <span>
                Added product <b>{entry.resource}</b>
            </span>
        );
    } else if (entry.action === "remove") {
        entryText = (
            <span>
                Removed product <b>{entry.resource}</b>
            </span>
        );
    } else if (entry.action === "change-quantity") {
        entryText = (
            <span>
                Changed quantity of <b>{entry.resource}</b>
            </span>
        );
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={6}>{entryText}</Col>
                <Col xs={4} align="center">
                    {new Date(entry.when).toLocaleString()}
                </Col>
                <Col xs={2}>
                    <a href={entry.linkId}>
                        <Icon path={mdiOpenInNew} />
                    </a>
                </Col>
            </Row>
            <hr />
        </React.Fragment>
    );
};

const HistoryModal = ({ showHistoryModal, setShowHistoryModal }) => {
    const history = getHistory();
    return (
        <Modal show={showHistoryModal} dialogClassName="modal-custom">
            <Modal.Header
                closeButton
                onClick={() => {
                    setShowHistoryModal(false);
                }}
            >
                <Modal.Title>History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {history.length === 0 ? (
                    <h4>No history entries are present</h4>
                ) : (
                    history
                        .sort((a, b) => {
                            return b.when - a.when;
                        })
                        .map((entry, i) => {
                            return <HistoryEntry key={i} entry={entry} />;
                        })
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setShowHistoryModal(false);
                    }}
                >
                    Close
                </Button>
                <Button
                    variant="primary"
                    disabled={history.length === 0}
                    onClick={() => {
                        clearHistory();
                        setShowHistoryModal(false);
                    }}
                >
                    Clear History
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default HistoryModal;
