import React, { useState } from "react";
import Icon from "@mdi/react";
import { getCompatibilityInfo } from "../API/compatibility";
import { mdiInformation, mdiAlert, mdiCheckboxMarkedCircle } from "@mdi/js";
import CompatibilityModal from "./compatibility-modal";
import useWindowDimensions from "./useWindowDimensions";

const Compatibility = ({ products, loading }) => {
    const { width } = useWindowDimensions();
    const [showCompatibilityModal, setShowCompatibilityModal] = useState(false);

    let { isCompatible, recommendations } = getCompatibilityInfo(
        products.slice(0)
    );

    // Some text does not fit well on small screens for compatibility, shortening the text with this, and
    // making it go into multiple lines did not look nice.
    const isSmallScreen = width < 800;

    let mainContent = null;
    if (loading) {
        mainContent = null;
    } else if (
        isCompatible === null ||
        !recommendations ||
        recommendations.length === 0
    ) {
        mainContent = (
            <div className="compatibility-alert bg-primary-dark">
                <div>
                    <span
                        style={{ paddingTop: "2px" }}
                        className="avoidwrap pl-1 py-1"
                    >
                      <Icon className="mb-1" path={mdiCheckboxMarkedCircle} />
                        Your build appears to be compatible based on our compatibility checks
                    </span>
                </div>
            </div>
        );
    } else if (isCompatible) {
        mainContent = (
            <div className="compatibility-alert bg-alert border-light-primary">
                <div>
                    <span className="avoidwrap" style={{ float: "left" }}>
                        <Icon path={mdiInformation} />
                    </span>
                    <span
                        style={{ paddingTop: "2px" }}
                        className="avoidwrap pl-1"
                    >
                        {isSmallScreen
                            ? "Compatibility checks available"
                            : "Compatibility verification checks available"}
                    </span>
                    <span style={{ float: "right", paddingTop: "2px" }}>
                        <a
                            className="clickable text-light"
                            onClick={() => setShowCompatibilityModal(true)}
                        >
                            <u>View Here</u>
                        </a>
                    </span>
                </div>
            </div>
        );
    } else {
        mainContent = (
            <div className="compatibility-alert bg-danger border-light-primary">
                <div>
                    <span className="avoidwrap" style={{ float: "left" }}>
                        <Icon path={mdiAlert} />
                    </span>
                    <span
                        style={{ paddingTop: "2px" }}
                        className="avoidwrap pl-1"
                    >
                        Compatibility problems detected
                    </span>
                    <span style={{ float: "right", paddingTop: "2px" }}>
                        <a
                            className="clickable text-light"
                            onClick={() => setShowCompatibilityModal(true)}
                        >
                            <u>View Here</u>
                        </a>
                    </span>
                </div>
            </div>
        );
    }

    const dangerRecs = recommendations.filter((r) => {
        return !r.isWarning;
    });
    const warningRecs = recommendations.filter((r) => {
        return r.isWarning;
    });

    return (
        <div>
            <CompatibilityModal
                showModal={showCompatibilityModal}
                setShowModal={setShowCompatibilityModal}
                dangerRecs={dangerRecs}
                warningRecs={warningRecs}
            />
            <div style={{ marginTop: "12px" }} className="md-show" />
            {mainContent}
            <div style={{ marginTop: "16px" }} className="md-show" />
        </div>
    );
};

export default Compatibility;
