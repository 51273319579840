import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { CheckCircle } from 'react-bootstrap-icons';

const ConfirmButton = (props) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const originalOnClick = props.onClick;
  const confirmText = props.confirmtext;
  const icon = <CheckCircle style={{backgroundColor: 'transparent'}} />
  const childProps = { ...props, onClick: undefined, confirmtext: undefined }
  return (
    <Button
        {...childProps}
        onClick={() => {
          originalOnClick();
          setShowConfirm(true);
          setTimeout(() => {
            setShowConfirm(false);
          }, 2000)
        }}
      >
        { showConfirm ?
            (
              <React.Fragment>
                { icon }
                { confirmText }
              </React.Fragment>
            )
            :
            ( props.children )
        }
      </Button>
  )
}

export default ConfirmButton
