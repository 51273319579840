import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
.btn :not(.switch.btn) {
  background: ${({ theme }) => theme.colors.nestedBackground};
}

.dropdown-menu {
  background: ${({ theme }) => theme.colors.nestedBackground};
}

.refinement_card {
  background: ${({ theme }) => theme.colors.nestedBackground};
}

.hits_card {
  background: ${({ theme }) => theme.colors.nestedBackground};
}

.hit_item {
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundLine};
}

.product_row {
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundLine};
}

.modal-content {
  background: ${({ theme }) => theme.colors.nestedBackground};
}

.site-name {
  color: ${({ theme }) => theme.colors.primary};
}
#link {
  color: ${({ theme }) => theme.colors.textColor};
  text-align: center;
}
#link::placeholder {
  color: ${({ theme }) => theme.colors.textColor};
  opacity: .6;
  font-weight: lighter;
  text-align: center;
}
.bg-primary {
  background: ${({ theme }) => theme.colors.primary}!important;
}
.text-light {
  color: ${({ theme }) => theme.colors.textColor};
}
.hit_title a {
  color: ${({ theme }) => theme.colors.linkColor};
}
body {
  color: ${({ theme }) => theme.colors.foreground};
  background-color: ${({ theme }) => theme.colors.background};
}
.builder_top_container {
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.textColor};
}
.green {
  color: ${({ theme }) => theme.colors.green};
}

.red {
  color: ${({ theme }) => theme.colors.alertColor};
}

.btn-primary {
  background: ${({ theme }) => theme.colors.primary};
}

.btn-primary:disabled {
  background: ${({ theme }) => theme.colors.primary};
}

.btn-primary:focus {
  background: ${({ theme }) => theme.colors.primary};
}

.btn-primary:active {
  background: ${({ theme }) => theme.colors.primary};
}

.btn-primary:hover {
  background: ${({ theme }) => theme.colors.primary};
  opacity: 0.8;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background: ${({ theme }) => theme.colors.background};
}

.btn-secondary {
  background: ${({ theme }) => theme.colors.background};
}

.btn-secondary:disabled {
  background: ${({ theme }) => theme.colors.background};
}

.btn-secondary:focus {
  background: ${({ theme }) => theme.colors.background};
}

.btn-secondary:active {
  background: ${({ theme }) => theme.colors.background};
}

.btn-secondary:hover {
  background: ${({ theme }) => theme.colors.background};
  opacity: 0.8;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background: ${({ theme }) => theme.colors.background};
}

.type_label {
  color: ${({ theme }) => theme.colors.foreground};
}

.text-red {
  color: ${({ theme }) => theme.colors.red};
}

.site_name {
  color: ${({ theme }) => theme.colors.primary};
}

.footer-socials {
  color: ${({ theme }) => theme.colors.primary};
}

.noUi-connect {
  background: ${({ theme }) => theme.colors.primary} !important;
}

.accessory-link {
  color: ${({ theme }) => theme.colors.primary};
}

.first-build-card {
  background: ${({ theme }) => theme.colors.primary} !important;
}

.border-light-primary {
  border: solid ${({ theme }) => theme.colors.lighterPrimary} 1.5px;
}

.build-link {
  border: solid ${({ theme }) => theme.colors.lighterPrimary} 1.5px;
}

.compatibility-alert.success {
  background-color: ${({ theme }) => theme.colors.lightGreen};
}

.compatibility-alert.danger {
  background-color: ${({ theme }) => theme.colors.lightRed};
  border: solid ${({ theme }) => theme.colors.lighterPrimary} 1.5px;
}

.compatibility-alert.info {
  background-color: ${({ theme }) => theme.colors.lightOrange};
  border: solid ${({ theme }) => theme.colors.lighterPrimary} 1.5px;
}

.text-primary {
  color: ${({ theme }) => theme.colors.primary} !important;
}

.nav-link {
  color: ${({ theme }) => theme.colors.primary} !important;
}

.text-danger {
  color: ${({ theme }) => theme.colors.dangerColor} !important;
}

.bg-danger {
  background-color: ${({ theme }) => theme.colors.dangerColor} !important;
}

.first-build-alert-container {
  background-color: ${({ theme }) => theme.colors.alertColor} !important;
  color: white;
}

.bg-alert {
  background-color: ${({ theme }) => theme.colors.alertColor} !important;
}

.text-alert {
  color: ${({ theme }) => theme.colors.alertColor} !important;
}

.light-separator {
  border-color: ${({ theme }) => theme.colors.lighterPrimary} !important;
}

.bg-primary-dark {
  background-color: ${({ theme }) => theme.colors.darkPrimary} !important;
}

.text-primary-dark {
  color: ${({ theme }) => theme.colors.darkPrimary} !important;
}

.bg-primary-darker {
  background-color: ${({ theme }) => theme.colors.darkerPrimary} !important;
}

.text-primary-darker {
  color: ${({ theme }) => theme.colors.darkerPrimary} !important;
}

.text-primary-lighter {
  color: ${({ theme }) => theme.colors.lighterPrimary} !important;
}

button.bg-primary:focus {
  background-color: ${({ theme }) => theme.colors.primary} !important;
}

`;
