import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Row, Col, Image } from 'react-bootstrap'
import { baseLocalStorageSource, baseOptions, sizeLocalStorageSource, sizeOptions, switchLocalStorageSource, switchOptions, defaultSwitchSelection, hotswapLocalStorageSource, hotswapOptions, defaultBaseSelection, defaultSizeSelection, defaultHotswapSelection } from '../API/user-preferences';

import Keyboard from '../Assets/keyboard.png';
import DIY from '../Assets/diy.png';
import Custom from '../Assets/custom.png'

import Size40 from '../Assets/size-40.png';
import Size60 from '../Assets/size-60.png';
import Size65 from '../Assets/size-65.png';
import Size75 from '../Assets/size-75.png';
import Size80 from '../Assets/size-80.png';
import Size100 from '../Assets/size-100.png';
import QuestionMark from '../Assets/question-mark.png';

import Linear from '../Assets/linear.png';
import Tactile from '../Assets/tactile.png';
import Clicky from '../Assets/clicky.png';

import Solder from '../Assets/solder.png';
import NoSolder from '../Assets/no-solder.png';

const localStorage = window.localStorage;

const NextStepsModal = ({ shouldShowNextSteps }) => {
  const [showNextStepsModal, setShowNextStepsModal] = useState(shouldShowNextSteps);

  let summaryContent = null;

  const storedBase = localStorage.getItem(baseLocalStorageSource);
  let selectedBase = baseOptions.find((base) => { return storedBase === base.value })
  if (!selectedBase) { selectedBase = baseOptions.find((base) => { return defaultBaseSelection === base.value }) }
  const base = {
    label: selectedBase.label,
    img: selectedBase.value === 'custom' ? Custom : selectedBase.value === 'prebuilt' ? Keyboard : DIY
  }

  const storedSize = localStorage.getItem(sizeLocalStorageSource);
  let selectedSize = sizeOptions.find((size) => { return storedSize === size.value })
  if (!selectedSize) { selectedSize = sizeOptions.find((base) => { return defaultSizeSelection === base.value }) }
  const size = {
    label: selectedSize.label,
    img: selectedSize.value === '40' ? Size40 : selectedSize.value === '60' ? Size60 : selectedSize.value === '65' ? Size65 : selectedSize.value === '75' ? Size75 : selectedSize.value === '80' ? Size80 : selectedSize.value === '100' ? Size100 : QuestionMark
  }

  const storedSwitch = localStorage.getItem(switchLocalStorageSource);
  let selectedSwitch = switchOptions.find((switchOption) => { return storedSwitch === switchOption.value })
  if (!selectedSwitch) { selectedSwitch = switchOptions.find((base) => { return defaultSwitchSelection === base.value }) }
  const switchStep = {
    label: selectedSwitch.label,
    img: selectedSwitch.value === "Linear" ? Linear : selectedSwitch.value === "Tactile" ? Tactile : selectedSwitch.value === "Clicky" ? Clicky : QuestionMark
  }

  const storedHotswap = localStorage.getItem(hotswapLocalStorageSource);
  let selectedHotswap = hotswapOptions.find((hotswap) => { return storedHotswap === hotswap.value })
  if (!selectedHotswap) { selectedHotswap = hotswapOptions.find((base) => { return defaultHotswapSelection === base.value }) }
  const hotswap = {
    label: selectedHotswap.value === 'hotswap' ? 'No, hotswap only' : 'Yes',
    img: selectedHotswap.value === 'hotswap' ? NoSolder : Solder
  }

  summaryContent = (
    <div>
      <h3>
      </h3>
      <Row>
        <Col md={3}>
          <br className="md-show" />
          <div className="ml-auto mr-auto text-center">
            Base - <b>{base.label}</b>
            <br/>
            <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
              <Image style={{ height: '100px', width: 'auto' }} className="p-2" variant="top" src={base.img} />
            </div>
          </div>
        </Col>
        <Col md={3}>
          <br className="md-show" />
          <div className="ml-auto mr-auto text-center">
            Size - <b>{size.label}</b>
            <br/>
            <div style={{ width: '100%',  marginLeft: 'auto', marginRight: 'auto'}}>
            <Image style={{ height: '100px', width: 'auto' }} className="p-2" variant="top" src={size.img} />
            </div>
          </div>
        </Col>
        <Col md={3}>
          <br className="md-show" />
          <div className="ml-auto mr-auto text-center">
            Switch - <b>{switchStep.label}</b>
            <br/>
            <div style={{ width: '100%',  marginLeft: 'auto', marginRight: 'auto'}}>
            <Image style={{ height: '100px', width: 'auto' }} className="p-2" variant="top" src={switchStep.img} />
            </div>
          </div>
        </Col>
        <Col md={3}>
          <br className="md-show" />
          <div className="ml-auto mr-auto text-center">
            Soldering - <b>{hotswap.label}</b>
            <br/>
            <div style={{ width: '100%',  marginLeft: 'auto', marginRight: 'auto' }}>
            <Image style={{ height: '100px', width: 'auto' }} className="p-2" variant="top" src={hotswap.img} />
            </div>
          </div>
        </Col>
      </Row>
      <br className="mt-3 mb-3"/>
      <div>
        These filters will be automatically applied when searching for applicable products.
      </div>
      <div className="mt-3">
        Want to change your selections?
        <Link className="ml-2 clickable" to="/first-build"><Button variant="secondary" style={{ fontSize: '.8rem' }}>Redo Questionnaire</Button></Link>
      </div>
    </div>
  )

  const whatsNextContent = (
    <div>
      <h4>What&apos;s next?</h4>
      We have prefilled your build with some essential tools to assist you in the construction of your keyboard.
      Also included is lubrication which may be applied to your switches to improve the sound and feel of every click.
      <h5 className="mt-4">Pick your parts</h5>
      Click the <span className="text-primary"><b>SELECT</b></span> button next to each part to start filling in the missing parts of your keyboard.
      <h5 className="mt-4">Watch out for compatibility issues</h5>
      <div>
        Potential <span className="text-danger"><b>Compatibility Issues</b></span> and <span className="text-alert"><b>Verification Checks</b></span> will be presented to you as more parts are selected. Be sure to understand if your parts are compatible prior to making any purchases.
      </div>
    </div>
  )

  return (
    <Modal show={showNextStepsModal} dialogClassName="modal-custom" onHide={() => {}}>
      <Modal.Header closeButton onClick={() => { setShowNextStepsModal(false) }}>
        Summary
      </Modal.Header>
      <Modal.Body>
        {summaryContent}
        <hr className="mt-3 mb-3" />
        { whatsNextContent }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowNextStepsModal(false) }>Continue</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NextStepsModal
