import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootswatch/dist/materia/bootstrap.min.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "./Styles/materialdesignicons.css";
import "@algolia/autocomplete-theme-classic/dist/theme.css";

import { ThemeToggler } from "./Components/theme-toggler";
import KeyboardBuilderPage from "./Pages/keyboard-builder";
import ListingPage from "./Pages/product-listing";
import ProductPage from "./Pages/product-page";
import AdminProductPage from "./Pages/admin-product-page";
import FirstBuildPage from "./Pages/first-build";
import ToS from "./Pages/tos";
import ContactUs from "./Pages/contact-us";
import NotFound from "./Pages/not-found";
import Disclaimer from "./Pages/disclaimer";
import PrivacyPolicy from "./Pages/privacy-policy";
import ProtectedRoute from "./Components/protected-route";
import "./Styles/app.css";
import AppLayout from "./Components/app-layout";

import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./Styles/theme";
import { GlobalStyles } from "./Styles/global";
import { ProductTypeToNameMap } from "./constants";
import ScrollToTop from "react-scroll-to-top";
import FAQ from "./Pages/faq";

function App() {
    const [theme, mountedComponent] = ThemeToggler();
    const themeMode = theme === "light" ? lightTheme : darkTheme;
    const [searchState, setSearchState] = useState({});
    const listingRoute = `/listing/:type(${Object.keys(
        ProductTypeToNameMap
    ).join("|")})`;

    if (!mountedComponent) return <div />;
    return (
        <ThemeProvider theme={themeMode}>
            <GlobalStyles />
            <Router>
                <AppLayout
                    theme={theme}
                    searchState={searchState}
                    setSearchState={setSearchState}
                >
                    <Switch>
                        <ProtectedRoute
                            exact
                            path="/admin"
                            component={AdminProductPage}
                        />
                        <Route
                            path={listingRoute}
                            render={(props) => (
                                <ListingPage
                                    {...props}
                                    searchState={searchState}
                                />
                            )}
                        />
                        <Route exact path="/" component={KeyboardBuilderPage} />
                        <Route
                            path="/build/:browserLinkId"
                            component={KeyboardBuilderPage}
                        />
                        <Route
                            path="/product/:productId"
                            component={ProductPage}
                        />
                        <Route path="/first-build" component={FirstBuildPage} />
                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        />
                        <Route path="/tos" component={ToS} />
                        <Route path="/faq" component={FAQ} />
                        <Route path="/contact-us" component={ContactUs} />
                        <Route path="/disclaimers" component={Disclaimer} />
                        <Route component={NotFound} />
                    </Switch>
                </AppLayout>
            </Router>
            <ScrollToTop smooth />
        </ThemeProvider>
    );
}

export default App;
