import React, { Suspense, useState, useEffect } from "react";
import "instantsearch.css/themes/algolia.css";
import { Alert, Col, Row, Card, Button } from "react-bootstrap";
import { withRouter, useParams } from "react-router-dom";
import CachedSearchClient from "../API/cached-search-client";
import {
    Configure,
    InstantSearch,
    Stats,
    connectSearchBox,
} from "react-instantsearch-dom";
import ProductHits from "../Components/product-hits";
import ProductRecommendations from "../Components/product-recommendations";
import ProductRefinementList from "../Components/product-refinement-list";
import ProductRefinementSlider from "../Components/product-refinement-slider";
import SizeRefinementRadio from "../Components/size-refinement-radio";
import ProductCompatibility from "../Components/product-compatibility";
import useWindowDimensions from "../Components/useWindowDimensions";
import "../Styles/product-listing.css";
import Container from "react-bootstrap/Container";
import CountryIcon from "../Components/country-icon";
import ScrollToTopOnMount from "../Components/scroll-to-top-mount";
import Collapsible from "react-collapsible";
import {
    hotswapApplicableTypes,
    hotswapOptions,
    hotswapLocalStorageSource,
    defaultHotswapSelection,
    sizeApplicableTypes,
    sizeOptions,
    profileApplicableTypes,
    profileOptions,
    switchApplicableTypes,
    switchLocalStorageSource,
    switchOptions,
    getUserSelectedSize,
    getUserSelectedProfile,
    getUserSelectedSwitch,
} from "../API/user-preferences";
import { getIndividualCompatibilityInfo } from "../API/compatibility";
import { ProductTypeToNameMap } from "../constants";
import { algoliaIndex } from "../constants";
import { getAllCountryOptions } from "../API/countries";
import { Helmet } from "react-helmet";
const EditModal = React.lazy(() => import("../Components/edit-modal"));

const localStorage = window.localStorage;

const mobileMaxWidth = 992;

const VirtualSearchBox = connectSearchBox(() => null);

const ListingPage = ({ history, searchState }) => {
    const { width } = useWindowDimensions();
    const { type } = useParams();

    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showRefinements, setShowRefinements] = useState(false);
    const [shouldShowCollapseButton, setShouldShowCollapseButton] = useState(
        width < mobileMaxWidth
    );

    useEffect(() => {
        if (width >= mobileMaxWidth) {
            setShouldShowCollapseButton(false);
        } else {
            setShouldShowCollapseButton(true);
        }
    }, [width]);

    const cachedSearchClient = CachedSearchClient(
        loading,
        setLoading,
        setAlert
    );

    const defaultAvailabilityRefinement = [
        "Available for Purchase",
        "Pre-Order",
    ];

    // TODO: this is hardcoded, and a subset of the data in the keyboard-builder.js file
    const accessoriesProductRefinement = [
        "lube",
        "switch-essentials",
        "solder-essentials",
        "cable",
        "deskmat",
    ];
    const toolsRefinement = ["lube", "switch-essentials", "solder-essentials"];
    const extrasRefinement = ["cable", "deskmat"];
    const pcbRefinement = ["pcb"];
    const caseRefinement = ["case"];
    const plateRefinement = ["plate"];
    let defaultProductTypeRefinement = type === "all" ? [] : [type];
    defaultProductTypeRefinement =
        type === "accessories"
            ? accessoriesProductRefinement
            : defaultProductTypeRefinement;
    defaultProductTypeRefinement =
        type === "tools" ? toolsRefinement : defaultProductTypeRefinement;
    defaultProductTypeRefinement =
        type === "extras" ? extrasRefinement : defaultProductTypeRefinement;
    defaultProductTypeRefinement =
        type === "pcb" ? pcbRefinement : defaultProductTypeRefinement;
    defaultProductTypeRefinement =
        type === "case" ? caseRefinement : defaultProductTypeRefinement;
    defaultProductTypeRefinement =
        type === "plate" ? plateRefinement : defaultProductTypeRefinement;

    const curHotswap =
        localStorage.getItem(hotswapLocalStorageSource) ||
        defaultHotswapSelection;
    const selectedHotswap = hotswapOptions.find((opt) => {
        return curHotswap === opt.value;
    });
    const refineOnHotswap = hotswapApplicableTypes.indexOf(type) >= 0;
    const productFixedOptions = (() => {
        let fixedOptions = [];
        if (type === "pcb") {
            fixedOptions = Object.keys(ProductTypeToNameMap)
                .filter((key) => {
                    return key === "pcb" || key === "kit";
                })
                .map((key) => {
                    return { label: ProductTypeToNameMap[key], value: key };
                });
        } else if (type === "case") {
            fixedOptions = Object.keys(ProductTypeToNameMap)
                .filter((key) => {
                    return key === "case" || key === "kit";
                })
                .map((key) => {
                    return { label: ProductTypeToNameMap[key], value: key };
                });
        } else if (type === "plate") {
            fixedOptions = Object.keys(ProductTypeToNameMap)
                .filter((key) => {
                    return key === "plate" || key === "kit";
                })
                .map((key) => {
                    return { label: ProductTypeToNameMap[key], value: key };
                });
        } else {
            fixedOptions = Object.keys(ProductTypeToNameMap)
                .filter((key) => {
                    return key !== "all" && key !== "extras" && key !== "tools";
                })
                .map((key) => {
                    return { label: ProductTypeToNameMap[key], value: key };
                });
        }
        return fixedOptions;
    })();

    const locationFixedOptions = getAllCountryOptions().map(
        (countryOptions) => {
            return {
                label: (
                    <span>
                        <CountryIcon location={countryOptions.countryKey} />
                        <span className="pl-2">{countryOptions.country}</span>
                    </span>
                ),
                value: countryOptions.countryKey,
            };
        }
    );

    let products = sessionStorage.getItem("products");
    try {
        products = JSON.parse(sessionStorage.getItem("products"));
    } catch (e) {
        products = [];
    }

    const refineOnSize = sizeApplicableTypes.indexOf(type) >= 0;
    const { sizeCompatibility, clearSizeCompatibility } =
        getUserSelectedSize(products);

    const defaultProfile = getUserSelectedProfile(products);
    const defaultSwitch = getUserSelectedSwitch(products);

    const recommendations = getIndividualCompatibilityInfo(products, type);
    const prettyType = type !== "all" ? ProductTypeToNameMap[type] : "Parts";

    const getDescription = () => {
        if (type === "case") {
            return "Find the perfect mechanical keyboard case for your build. Choose from a wide selection of materials, colors, and styles to match your personal style. Search now to find the perfect case for your needs";
        } else if (type === "pcb") {
            return "Looking for the perfect PCB for your mechanical keyboard build? Our selection includes a variety of sizes, styles, and features (e.g. hotswap) to choose from. Search now to find the right PCB for your needs.";
        } else if (type === "switch") {
            return "Find the perfect switch for your mechanical keyboard build. Choose from a wide selection of switch types, brands, and colors to find the perfect feel and sound. Search now to find the right switch for your needs.";
        } else if (type === "cap") {
            return "Find the perfect keycaps for your mechanical keyboard build. Choose from a wide selection of materials, colors, and styles to match your personal style. Search now to find the perfect keycaps for your needs.";
        } else if (type === "plate") {
            return "Find the perfect plate for your mechanical keyboard build. Choose from a wide selection of materials, colors, and styles to match your personal style. Search now to find the perfect plate for your needs.";
        } else if (type === "kit") {
            return "Find the perfect DIY kit for your mechanical keyboard build. Choose from a wide selection of kits that include everything you need to build your own keyboard. Search now to find the perfect DIY kit for your needs.";
        } else if (type === "prebuilt") {
            return "Find the perfect prebuilt mechanical keyboard. Choose from a wide selection of styles, sizes, and features to find the perfect keyboard for your needs. Search now to find the perfect prebuilt keyboard for you.";
        } else {
            return "Looking for a specific mechanical keyboard part? Use our search feature to find what you need. From switches to keycaps, we've got a wide range of parts to choose from.";
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Pick Your {prettyType} | Keyboard Part Picker</title>
                <meta name="description" content={getDescription()} />
                <meta
                    property="og:title"
                    content={`Pick Your ${prettyType} | Keyboard Part Picker`}
                />
                <meta property="og:description" content={getDescription()} />
                <meta
                    name="twitter:title"
                    content={`Pick Your ${prettyType} | Keyboard Part Picker`}
                />
                <meta name="twitter:description" content={getDescription()} />
            </Helmet>
            <Container fluid className="main">
                <ScrollToTopOnMount />
                <InstantSearch
                    searchClient={cachedSearchClient}
                    indexName={algoliaIndex}
                >
                    <Configure clickAnalytics />
                    <VirtualSearchBox defaultRefinement={searchState.query} />
                    <Row>
                        <Col lg={4} xl={3}>
                            <div>
                                <ProductCompatibility
                                    recommendations={recommendations}
                                    type={type}
                                    typeName={ProductTypeToNameMap[type]}
                                />
                            </div>
                            {shouldShowCollapseButton ? (
                                <Button
                                    style={{ marginBottom: "20px" }}
                                    onClick={() => {
                                        setShowRefinements(!showRefinements);
                                    }}
                                >
                                    {showRefinements
                                        ? "Hide Filters"
                                        : "Show Filters"}
                                </Button>
                            ) : null}
                            <Collapsible
                                open={
                                    showRefinements || !shouldShowCollapseButton
                                }
                                transitionTime={100}
                            >
                                <div style={{ padding: "2px" }}>
                                    <ProductRefinementList
                                        title="Availability"
                                        visible={true}
                                        attribute="availability"
                                        defaultRefinement={
                                            defaultAvailabilityRefinement
                                        }
                                        fixedOptions={[
                                            {
                                                label: "Available for Purchase",
                                                value: "Available for Purchase",
                                            },
                                            {
                                                label: "Pre-Order",
                                                value: "Pre-Order",
                                            },
                                            {
                                                label: "Interest Check",
                                                value: "Interest Check",
                                            },
                                        ]}
                                        value={true}
                                    />
                                    <ProductRefinementList
                                        title="Hotswap"
                                        visible={refineOnHotswap}
                                        defaultRefinement={
                                            refineOnHotswap &&
                                            selectedHotswap &&
                                            selectedHotswap.value &&
                                            selectedHotswap.value !== "ALL"
                                                ? selectedHotswap.value
                                                : []
                                        }
                                        availableRefinements={hotswapOptions}
                                        attribute="pcbCompatibility"
                                        localStorageSource={
                                            hotswapLocalStorageSource
                                        }
                                        fixedOptions={hotswapOptions.filter(
                                            (s) => {
                                                return !s.hideAsFacet;
                                            }
                                        )}
                                    />
                                    <ProductRefinementList
                                        visible={
                                            type === "all" ||
                                            type === "accessories" ||
                                            type === "tools" ||
                                            type === "extras" ||
                                            type === "pcb" ||
                                            type === "case" ||
                                            type == "plate"
                                        }
                                        title="Product Type"
                                        attribute="type"
                                        defaultRefinement={
                                            defaultProductTypeRefinement
                                        }
                                        limit={30}
                                        fixedOptions={
                                            productFixedOptions.length > 0
                                                ? productFixedOptions
                                                : null
                                        }
                                    />
                                    <ProductRefinementList
                                        title="Keycap Profile"
                                        visible={
                                            profileApplicableTypes.indexOf(
                                                type
                                            ) >= 0
                                        }
                                        defaultRefinement={
                                            type === "cap" && defaultProfile
                                                ? [defaultProfile]
                                                : []
                                        }
                                        availableRefinements={profileOptions}
                                        attribute="profileCompatibility"
                                        fixedOptions={profileOptions.filter(
                                            (s) => {
                                                return !s.hideAsFacet;
                                            }
                                        )}
                                    />
                                    <ProductRefinementList
                                        title="Switch Type"
                                        visible={
                                            switchApplicableTypes.indexOf(
                                                type
                                            ) >= 0
                                        }
                                        defaultRefinement={
                                            type === "switch" && defaultSwitch
                                                ? [defaultSwitch]
                                                : []
                                        }
                                        availableRefinements={switchOptions}
                                        attribute="switchCompatibility"
                                        localStorageSource={
                                            switchLocalStorageSource
                                        }
                                        fixedOptions={switchOptions.filter(
                                            (s) => {
                                                return !s.hideAsFacet;
                                            }
                                        )}
                                    />
                                    <SizeRefinementRadio
                                        title="Size"
                                        visible={refineOnSize}
                                        defaultRefinement={
                                            refineOnSize && sizeCompatibility
                                                ? [sizeCompatibility]
                                                : []
                                        }
                                        clearRefinement={clearSizeCompatibility}
                                        singleSelect={true}
                                        availableRefinements={sizeOptions}
                                        attribute="sizeCompatibility"
                                        fixedOptions={sizeOptions.filter(
                                            (s) => {
                                                return !s.hideAsFacet;
                                            }
                                        )}
                                    />
                                    <ProductRefinementSlider
                                        visible={true}
                                        title="Price"
                                        attribute="price"
                                    />
                                    <ProductRefinementList
                                        title="Vendor Country"
                                        visible={true}
                                        defaultRefinement={[]}
                                        attribute="location"
                                        fixedOptions={locationFixedOptions}
                                    />
                                    <ProductRefinementList
                                        title="Website"
                                        visible={true}
                                        attribute="website"
                                        limit={30}
                                    />
                                    <ProductRefinementList
                                        title="Vendor"
                                        visible={true}
                                        attribute="vendor"
                                        limit={30}
                                    />
                                </div>
                            </Collapsible>
                            {shouldShowCollapseButton && showRefinements ? (
                                <Button
                                    style={{ marginBottom: "20px" }}
                                    onClick={() => {
                                        const scrollUpButton =
                                            document.querySelector(
                                                ".ScrollUpButton__Container "
                                            );
                                        if (scrollUpButton) {
                                            scrollUpButton.click();
                                        }
                                        setShowRefinements(!showRefinements);
                                    }}
                                >
                                    {showRefinements
                                        ? "Hide Filters"
                                        : "Show Filters"}
                                </Button>
                            ) : null}
                        </Col>
                        <Col lg={8} xl={9}>
                            <Alert show={alert != null} variant="danger">
                                {alert}
                            </Alert>
                            <ProductRecommendations
                                history={history}
                                type={type}
                                prettyType={prettyType}
                                mobileMaxWidth={mobileMaxWidth}
                            />
                            <Suspense fallback="">
                                <EditModal
                                    showEditModal={showEditModal}
                                    setShowEditModal={setShowEditModal}
                                />
                            </Suspense>
                            {window.showAdmin ? (
                                <Button onClick={() => setShowEditModal(true)}>
                                    Add new product
                                </Button>
                            ) : null}

                            <Card className="hits_card">
                                <Stats />
                                <ProductHits
                                    loading={loading}
                                    setLoading={setLoading}
                                    history={history}
                                />
                            </Card>
                        </Col>
                    </Row>
                </InstantSearch>
            </Container>
        </React.Fragment>
    );
};

export default withRouter(ListingPage);
