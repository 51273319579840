import ProductRow from "./product-row";
import { connectHitInsights } from "react-instantsearch-dom";
import { algoliaAppId, algoliaApiKey } from "../constants";
// eslint-disable-next-line no-undef
const aa = require("search-insights");
aa("init", {
    appId: algoliaAppId,
    apiKey: algoliaApiKey,
    useCookie: true,
});

export default connectHitInsights(aa)(ProductRow);
