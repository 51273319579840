import React from 'react';

import ContentLoader from "react-content-loader"

const ProductPlaceholder = (props) => (
  // TODO The rectangles drawn should be a percentage based on the container, as well as the configured style.
  <div className="hit_placeholder">
    <ContentLoader
      speed={2}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
      style={{
        width: '300px',
        height: '90px',
        marginTop: '-15px'
      }}
    >
      <rect x="0" y="12" rx="0" ry="0" width="99" height="92" />
      <rect x="120" y="22" rx="0" ry="0" width="180" height="12" />
      <rect x="120" y="46" rx="0" ry="0" width="180" height="12" />
      <rect x="120" y="70" rx="0" ry="0" width="180" height="12" />
    </ContentLoader>
  </div>
)

export default ProductPlaceholder
