import { adminUrl } from '../constants';

const fetch = window.fetch;

window.showAdmin = sessionStorage.getItem('showAdmin') && sessionStorage.getItem('showAdmin') === 'true';
window.turnOnAdminControls = () => {
  sessionStorage.setItem('showAdmin', 'true');
  window.showAdmin = true;
}

window.turnOffAdminControls = () => {
  sessionStorage.setItem('showAdmin', 'false');
  window.showAdmin = false;
}

export const addNewProduct = async(newProductInformation) => {
  const apikey = window.localStorage.getItem('keyboard-partpicker-api-key');
  if (!apikey) {
    console.error('no api key given');
    return;
  }
  const response = await window.fetch(`${adminUrl}/product`, {
    method: 'POST',
    headers: {
      'x-api-key': apikey,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newProduct: {
        ...newProductInformation
      }
    })
  }).then(response => response.json()).then(responseObject => responseObject);

  const { newProduct } = response;

  if (newProduct && newProduct.id) {
    return newProduct;
  }
  return undefined
}

export const updateAdminProduct = async(productInput) => {
  const apikey = window.localStorage.getItem('keyboard-partpicker-api-key');
  if (!apikey) {
    throw new Error('No api key given');
  }
  if (!productInput || !productInput.id) {
    throw new Error('Invalid product input provided');
  }
  const product = { ...productInput, _highlightResult: undefined, __position: undefined };

  fetch(`${adminUrl}/product/${product.id}`, {
    method: 'PUT',
    headers: {
      'x-api-key': apikey,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ updatedProduct: product })
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Failed update call');
    }
    return 'Success';
  });
}

export const deleteProductAdmin = async(id, setDeleted) => {
  const apikey = window.localStorage.getItem('keyboard-partpicker-api-key');
  if (!apikey) {
    console.error('no api key given');
    return;
  }
  const response = await window.fetch(`${adminUrl}/product/${id}`, {
    method: 'DELETE',
    headers: {
      'x-api-key': apikey,
      'Content-Type': 'application/json'
    }
  }).then(response => response.json()).then(responseObject => responseObject);

  if (response && response.success) {
    setDeleted(true);
  }
}

export const checkAPIkey = async(apiKey, setAuthenticationStatus) => {
  const response = await window.fetch(`${adminUrl}/`, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json'
    }
  }).then(response => response.json()).then(responseObject => responseObject);

  if (response.success === 'get call succeed!') {
    setAuthenticationStatus(true);
  }
  else {
    setAuthenticationStatus(false);
  }
}
