import React, { useCallback, useMemo } from "react";
import {
    Configure,
    connectSearchBox,
    InstantSearch,
} from "react-instantsearch-dom";
import CachedSearchClient from "../API/cached-search-client";
import {
    algoliaQuerySuggestionsIndex,
    algoliaIndex,
    ProductTypeToNameMap,
} from "../constants";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { Autocomplete } from "./auto-complete";
import { debounce } from "@algolia/autocomplete-shared";
import { matchPath, useHistory, withRouter } from "react-router-dom";
import ProductRow from "./product-row";
import { getAlgoliaFacets, getAlgoliaResults } from "@algolia/autocomplete-js";
import { Button } from "react-bootstrap";
import { Grid } from "react-bootstrap-icons";

const suggestedSearches = {
    case: ["brass"],
    plate: ["aluminum"],
    cap: ["GMK", "ortho", "ergo"],
    switch: ["silent", "gateron"],
    lube: ["205g0"],
};

const VirtualSearchBox = connectSearchBox(() => null);

const Search = ({ searchState, setSearchState }) => {
    const cachedSearchClient = CachedSearchClient(false, null);
    const debounceSetSearchState = debounce(setSearchState, 300);
    const history = useHistory();
    const typeMatch = matchPath(location.pathname, {
        path: "/listing/:type",
    });
    let currentPageType;
    if (typeMatch && typeMatch.params.type) {
        currentPageType = typeMatch.params.type;
    }

    const goToPage = (type) => {
        // Update page if needed
        if (!type || type.length === 0) {
            history.push(`/listing/all`);
        } else if (type !== currentPageType) {
            history.push(`/listing/${type}`);
        }
    };

    const onSubmit = useCallback(({ state }) => {
        setSearchState((searchState) => ({
            ...searchState,
            query: state.query,
        }));
        goToPage(state.type);
    }, []);

    const onStateChange = useCallback(({ prevState, state }) => {
        if (prevState.query !== state.query) {
            debounceSetSearchState({
                query: state.query,
            });
        }
    }, []);

    const onReset = useCallback(() => {
        setSearchState((searchState) => ({
            ...searchState,
            query: "",
            type: "",
        }));
    }, []);

    const plugins = useMemo(() => {
        const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
            key: "search",
            limit: 6,
            transformSource({ source }) {
                return {
                    ...source,
                    onSelect({ item }) {
                        setSearchState((searchState) => ({
                            ...searchState,
                            query: item.label,
                            type: item.category || "",
                        }));
                        goToPage(item.category);
                    },
                    getItems(params) {
                        return source.getItems(params);
                    },
                    templates: {
                        ...source.templates,
                        header({ items }) {
                            if (items.length === 0) {
                                return <></>;
                            }

                            return (
                                <>
                                    <span className="aa-SourceHeaderTitle">
                                        Recent searches
                                    </span>
                                    <span className="aa-SourceHeaderLine" />
                                </>
                            );
                        },
                    },
                };
            },
        });

        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient: cachedSearchClient,
            indexName: algoliaQuerySuggestionsIndex,
            getSearchParams() {
                return recentSearchesPlugin.data.getAlgoliaSearchParams({
                    hitsPerPage: 6,
                });
            },
            categoryAttribute: [
                algoliaIndex,
                "facets",
                "exact_matches",
                "type",
            ],
            transformSource({ source }) {
                return {
                    ...source,
                    sourceId: "querySuggestionsPlugin",
                    onSelect({ item }) {
                        setSearchState((searchState) => ({
                            ...searchState,
                            query: item.query,
                            type: item.__autocomplete_qsCategory || "",
                        }));
                        goToPage(item.__autocomplete_qsCategory);
                    },
                    getItems(params) {
                        if (!params.state.query) {
                            return [];
                        }
                        return source.getItems(params);
                    },
                    templates: {
                        ...source.templates,
                        header({ items }) {
                            if (items.length === 0) {
                                return <></>;
                            }

                            return (
                                <>
                                    <span className="aa-SourceHeaderTitle">
                                        Search suggestions
                                    </span>
                                    <span className="aa-SourceHeaderLine" />
                                </>
                            );
                        },
                    },
                };
            },
        });

        const popularPlugin = createQuerySuggestionsPlugin({
            searchClient: cachedSearchClient,
            indexName: algoliaQuerySuggestionsIndex,
            getSearchParams() {
                return {
                    query: "",
                    hitsPerPage: 6,
                };
            },
            transformSource({ source }) {
                return {
                    ...source,
                    sourceId: "popularPlugin",
                    getItemInputValue({ item }) {
                        return item.query;
                    },
                    onSelect({ item }) {
                        setSearchState((searchState) => ({
                            ...searchState,
                            query: item.query,
                        }));
                        goToPage(null);
                    },
                    templates: {
                        header({ Fragment }) {
                            return (
                                <Fragment>
                                    <span className="aa-SourceHeaderTitle">
                                        Popular searches
                                    </span>
                                    <div className="aa-SourceHeaderLine" />
                                </Fragment>
                            );
                        },
                        item({ item }) {
                            return <>{item.query}</>;
                        },
                    },
                };
            },
        });

        const productsPlugin = {
            getSources({ query }) {
                if (!query) {
                    return [];
                }

                return [
                    {
                        sourceId: "productsPlugin",
                        getItems({ setContext }) {
                            return getAlgoliaResults({
                                searchClient: cachedSearchClient,
                                queries: [
                                    {
                                        indexName: algoliaIndex,
                                        query,
                                        params: {
                                            hitsPerPage: 3,
                                            facetFilters: [
                                                "availability:Available for Purchase",
                                            ],
                                        },
                                    },
                                ],
                                transformResponse({ hits, results }) {
                                    setContext({
                                        nbProducts: results[0].nbHits,
                                    });

                                    return hits;
                                },
                            });
                        },
                        onSelect({ setIsOpen }) {
                            setIsOpen(true);
                        },
                        templates: {
                            header({ state, Fragment }) {
                                return (
                                    <Fragment>
                                        <div className="aa-SourceHeaderTitle">
                                            Products for {state.query}
                                        </div>
                                        <div className="aa-SourceHeaderLine" />
                                    </Fragment>
                                );
                            },
                            item({ item }) {
                                return (
                                    <ProductRow
                                        hit={item}
                                        hideFloatingButtons={true}
                                        providedHistory={history}
                                    />
                                );
                            },
                            footer({ state }) {
                                return (
                                    state.context.nbProducts > 3 && (
                                        <div style={{ textAlign: "center" }}>
                                            <Button
                                                onClick={() =>
                                                    onSubmit({ state })
                                                }
                                            >
                                                See All Parts (
                                                {state.context.nbProducts})
                                            </Button>
                                        </div>
                                    )
                                );
                            },
                        },
                    },
                ];
            },
        };

        const categoriesPlugin = {
            getSources({ query }) {
                if (!query) {
                    return [];
                }

                return [
                    {
                        sourceId: "categoriesPlugin",
                        getItems() {
                            return getAlgoliaResults({
                                searchClient: cachedSearchClient,
                                queries: [
                                    {
                                        indexName: algoliaIndex,
                                        query,
                                        params: {
                                            hitsPerPage: 1,
                                        },
                                    },
                                ],
                            });
                        },
                        onSelect({ item }) {
                            setSearchState((searchState) => ({
                                ...searchState,
                                type: item.type || "",
                            }));
                            goToPage(item.type);
                        },
                        templates: {
                            header({ Fragment }) {
                                return (
                                    <Fragment>
                                        <span className="aa-SourceHeaderTitle">
                                            Suggested category
                                        </span>
                                        <div className="aa-SourceHeaderLine" />
                                    </Fragment>
                                );
                            },
                            item({ item }) {
                                return (
                                    <div className="aa-ItemWrapper aa-CategoryItem">
                                        <div className="aa-ItemContent">
                                            <div className="aa-ItemIcon aa-ItemIcon--noBorder">
                                                <Grid />
                                            </div>
                                            <div className="aa-ItemContentBody">
                                                <div className="aa-ItemContentTitle">
                                                    {
                                                        ProductTypeToNameMap[
                                                            item.type
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            },
                        },
                    },
                ];
            },
        };

        const popularCategoriesPlugin = {
            getSources() {
                return [
                    {
                        sourceId: "popularCategoriesPlugin",
                        getItems() {
                            return getAlgoliaFacets({
                                searchClient: cachedSearchClient,
                                queries: [
                                    {
                                        indexName: algoliaIndex,
                                        facet: "type",
                                        params: {
                                            facetQuery: "",
                                            maxFacetHits: 6,
                                        },
                                    },
                                ],
                            });
                        },
                        getItemInputValue({ item }) {
                            return item.label;
                        },
                        onSelect({ item }) {
                            setSearchState((searchState) => ({
                                ...searchState,
                                query: "",
                                type: item.label || "",
                            }));
                            goToPage(item.label);
                        },
                        templates: {
                            header({ Fragment }) {
                                return (
                                    <Fragment>
                                        <span className="aa-SourceHeaderTitle">
                                            Popular categories
                                        </span>
                                        <div className="aa-SourceHeaderLine" />
                                    </Fragment>
                                );
                            },
                            item({ item }) {
                                return (
                                    <div className="aa-ItemWrapper aa-PopularCategoryItem">
                                        <div className="aa-ItemContent">
                                            <div className="aa-ItemContentBody">
                                                <div className="aa-ItemContentTitle">
                                                    {
                                                        ProductTypeToNameMap[
                                                            item.label
                                                        ]
                                                    }{" "}
                                                    <span>({item.count})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            },
                        },
                    },
                ];
            },
        };

        const plugins = [];
        if (!currentPageType) {
            plugins.push(recentSearchesPlugin);
            plugins.push(querySuggestionsPlugin);
            plugins.push(popularPlugin);
            plugins.push(productsPlugin);
            plugins.push(categoriesPlugin);
            plugins.push(popularCategoriesPlugin);
        }
        return plugins;
    }, [currentPageType]);

    const currentSuggestedSearches =
        currentPageType && currentPageType.length > 0
            ? suggestedSearches[currentPageType]
                ? suggestedSearches[currentPageType]
                : []
            : [];

    const placeholderType =
        currentPageType && currentPageType.length > 0
            ? ProductTypeToNameMap[currentPageType]
            : "parts";
    const placeholder =
        currentSuggestedSearches.length > 0
            ? `Search ${placeholderType} (i.e. ${currentSuggestedSearches.join(
                  ", "
              )})`
            : `Search ${placeholderType}`;

    return (
        <InstantSearch
            searchClient={cachedSearchClient}
            indexName={algoliaIndex}
        >
            <VirtualSearchBox />
            <Autocomplete
                placeholder={placeholder}
                detachedMediaQuery="none"
                initialState={{
                    query: searchState.query,
                }}
                openOnFocus={true}
                onSubmit={onSubmit}
                onStateChange={onStateChange}
                onReset={onReset}
                plugins={plugins}
            />
            <Configure clickAnalytics />
        </InstantSearch>
    );
};

export default withRouter(Search);
