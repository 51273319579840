import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react'
import { mdiWeatherSunny, mdiWeatherNight } from '@mdi/js';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const hideButton = true;

export const ThemeToggler = () => {
    const [theme, setTheme] = useState('light');
    const [mountedComponent, setMountedComponent] = useState(false);

    const setMode = mode => {
        window.localStorage.setItem('theme', mode);
        setTheme(mode);
    };

    const toggleTheme = () => {
        theme === 'light' ? setMode('dark') : setMode('light');
    };

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme');
        localTheme && setTheme(localTheme);
        setMountedComponent(true);
    }, []);
    return [theme, mountedComponent, (
        hideButton ? null :
        <BootstrapSwitchButton
            key="theme-toggle-btn"
            bsPrefix="theme-toggle-btn"
            checked={theme === 'light'}
            onstyle="light"
            offstyle="dark"
            style="border"
            size="sm" 
            onlabel={<Icon path={mdiWeatherSunny}/>}
            offlabel={<Icon path={mdiWeatherNight}/>}
            onChange={toggleTheme}
        />
    )];
};