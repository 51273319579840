// The default will show if we are a search page that is part of the 'relevantSearchPages'
// AND we already have a product that is also a part of the 'relevantSearchPages'
const defaultShouldShowOnSearchPage = (products, type, compatibilityInfo) => {
  const onRelevantSearchPage = compatibilityInfo.relevantSearchPages.indexOf(type) >= 0;
  const containsExistingConflictingProduct = products.some((p) => { return compatibilityInfo.relevantSearchPages.indexOf(p.type) >= 0 })
  return onRelevantSearchPage && containsExistingConflictingProduct;
}

const getCompatibilities = (products) => {
  return [
    // All sizes are the same
    {
      ...validateCompatibilitiesMatch(products, 'sizeCompatibility'),
      relevantSearchPages: ['pcb', 'case', 'plate', 'kit', 'prebuilt'],
      shouldShowOnSearchPage: () => { return false },
      autoFill: () => {}
    },

    // All keycap profiles are the same
    {
      ...validateCompatibilitiesMatch(products, 'profileCompatibility'),
      relevantSearchPages: ['cap'],
      shouldShowOnSearchPage: () => { return false },
      autoFill: () => {}
    },

    // All switch types are the same
    {
      ...validateCompatibilitiesMatch(products, 'switchCompatibility'),
      relevantSearchPages: ['switch'],
      shouldShowOnSearchPage: () => { return false },
      autoFill: () => {}
    },

    // Switches and PCBs must be able to support each other
    // If PCB is 5 pin, then switch can be 3 or 5 pin
    // If PCB is 3 pin, switch must be 3 pin
    //'pinCompatibility': { ...validateCompatibilitiesMatch(products, 'pinCompatibility', '3pin') },
    {
      isCompatible: false,
      relevantProducts: products ? products.filter((p) => { return p.type === 'pcb' || p.type === 'switch' || p.type === 'kit' }) : [],
      compatibilityType: 'pinCompatibility',
      required: [
        ['switch'],
        ['pcb', 'kit']
      ],
      isWarning: true,
      relevantSearchPages: ['pcb', 'kit', 'prebuilt', 'switch'],
      shouldShowOnSearchPage: (products, type, compatibilityInfo) => {
        if (!products) { products = [] }
        const onRelevantSearchPage = compatibilityInfo.relevantSearchPages.indexOf(type) >= 0;
        let isRelevantBasedOnCurProducts = false;
        if (type === 'switch') {
          // For switches, we should show a pin compat warning if we've already selected a kit, pcb, or prebuilt
          isRelevantBasedOnCurProducts = products.some((p) => { return p.type === 'kit' || p.type === 'pcb' || p.type === 'prebuilt' })
        }
        else {
          // For kit, pcb, or prebuilt, we should show the pin compat warning if we've already selected a switch
          isRelevantBasedOnCurProducts = products.some((p) => { return p.type === 'switch' });
        }
        return isRelevantBasedOnCurProducts && onRelevantSearchPage
      }
    },

    // Format of the keycaps w/ something else, etc
    // If this is ergo
    // commented because this is complicated to explain.
    /*{
      isCompatible: false,
      relevantProducts: products.filter((p) => { return p.type === 'cap' || p.type === 'kit' || p.type === 'pcb' || p.type === 'plate' || p.type === 'case' }),
      compatibilityType: 'formatCompatibility',
      required: [
        ['cap'],
        ['kit', 'pcb', 'plate', 'case']
      ],
      isWarning: true
    },*/

    // Number of keycaps and switches needed to complete this build
    {
      isCompatible: false,
      relevantProducts: products ? products.filter((p) => { return p.type === 'pcb' || p.type === 'kit' || p.type === 'cap' || p.type === 'switch' }) : [],
      compatibilityType: 'keycountCompatibility',
      required: [
        ['pcb', 'kit'],
        ['cap', 'switch']
      ],
      isWarning: true,
      relevantSearchPages: ['cap'],
      shouldShowOnSearchPage: (products, type, compatibilityInfo) => {
        const onRelevantSearchPage = compatibilityInfo.relevantSearchPages.indexOf(type) >= 0;
        let isRelevantBasedOnCurProducts = false;
        if (type === 'switch' || type === 'cap') {
          // For switches, we should show a pin compat warning if we've already selected a kit, pcb, or prebuilt
          isRelevantBasedOnCurProducts = products.some((p) => { return p.type === 'pcb' || p.type === 'kit' })
        }
        return onRelevantSearchPage && isRelevantBasedOnCurProducts
      }
    },

    // pcb and case have the same slot for power supply
    {
      isCompatible: false,
      relevantProducts: products ? products.filter((p) => { return p.type === 'case' || p.type === 'pcb' }) : [],
      compatibilityType: 'portCompatibility',
      required: [
        ['case'],
        ['pcb']
      ],
      isWarning: true,
      relevantSearchPages: ['case', 'pcb'],
      shouldShowOnSearchPage: defaultShouldShowOnSearchPage
    },

    // pcb and case have the same slot for power supply
    {
      isCompatible: false,
      relevantProducts: products ? products.filter((p) => { return p.type === 'pcb' || p.type === 'plate' || p.type === 'kit' || p.type === 'cap' || p.type === 'stabilizer' }) : [],
      compatibilityType: 'largeKeysCompatibility',
      required: [
        ['pcb', 'kit'],
        ['cap', 'stabilizer']
      ],
      relevantSearchPages: ['pcb', 'kit', 'cap', 'stabilizer'],
      isWarning: true,
      shouldShowOnSearchPage: defaultShouldShowOnSearchPage
    },

    {
      isCompatible: false,
      relevantProducts: products ? products.filter((p) => { return p.type === 'pcb' || p.type === 'plate' || p.type === 'case' }) : [],
      compatibilityType: 'proprietaryCompatibility',
      required: [
        ['pcb'],
        ['plate', 'case']
      ],
      relevantSearchPages: ['pcb', 'plate', 'case'],
      isWarning: true,
      shouldShowOnSearchPage: defaultShouldShowOnSearchPage
    },
  ]
}

const validateCompatibilitiesMatch = (products, compatibilityField, compatibilityValue) => {
  if (!products) products = []
  let isCompatible = true;
  let cur = null;
  const filteredProducts = products.filter((p) => {
    return p[compatibilityField];
  }).map((p) => {
    if (!cur) {
      cur = p;
    }
    isCompatible = isCompatible &&
      (compatibilityValue ? compatibilityValue === p[compatibilityField] : cur[compatibilityField] === p[compatibilityField])
    return p;
  });

  return {
    isCompatible: isCompatible,
    relevantProducts: filteredProducts,
    compatibilityType: compatibilityField
  };
}

export const getIndividualCompatibilityInfo = (products, type) => {
  if (!products) products = []
  const compatibilities = getCompatibilities(products);
  return compatibilities.filter((compatibilityInfo) => {
    let shouldShowOnSearchPage = false;
    if (compatibilityInfo.shouldShowOnSearchPage && compatibilityInfo.relevantSearchPages) {
      shouldShowOnSearchPage = compatibilityInfo.shouldShowOnSearchPage(products, type, compatibilityInfo);
    }
    return shouldShowOnSearchPage;
  });
}

// TODO: Make checker for applicable types to each compatibility check.
// required references what types are required in a list of products to include this in a build.
// The outer array = AND, inner array = OR
export const getCompatibilityInfo = (products) => {
  if (!products) products = []
  const compatibilities = getCompatibilities(products);

  // Make a final determination - is this build compatible?
  let isCompatible = true;
  compatibilities.filter((c) => {
    return !c.isWarning;
  }).map((c) => {
    isCompatible = isCompatible && c.isCompatible;
    return c;
  })

  const recommendations = compatibilities.filter((c) => {
    return !c.isCompatible
  }).filter((c) => {
    if (c.required) {
      let containsRequired = true;
      c.required.forEach((possibleTypes) => {
        let containsPossibleType = false;
        possibleTypes.forEach((type) => {
          containsPossibleType = containsPossibleType || products.some((p) => { return p.type === type })
        })
        containsRequired = containsRequired && containsPossibleType;
      })
      return containsRequired;
    }
    else {
      return !c.isCompatible
    }
  })

  return { isCompatible: isCompatible, recommendations: recommendations };
};
