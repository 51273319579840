import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom'
import { Alert, Container, Spinner } from 'react-bootstrap';

import {
    getProductById,
}
    from '../API/product';

const ProductPage = () => {
    let { productId } = useParams();
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const bootstrap = async () => {
            if (productId) {
                try {
                    const product = await getProductById(productId);
                    const link = product.affiliateLink ? product.affiliateLink : product.link;
                    window.location.assign(link);
                } catch (err) {
                    setAlert(err.message);
                }
                setLoading(false);
            }
        }
        bootstrap();
    });

    return (
        <Container fluid className="main">
            {
                loading ?
                    (
                        <Spinner className="horizontal-center spinner" style={{marginTop: "100px"}} animation="border" />
                    )
                    :
                    <Alert show={alert != null} variant="warning">
                        {alert}
                    </Alert>
            }

        </Container>
    );
}

export default withRouter(ProductPage);
