import { backendUrl, publicApiKey } from '../constants';
import axios from 'axios';
import { getErrorMessage } from './common';

const axiosOptions = {
  headers: {
    'x-api-key': publicApiKey
  }
};

export const getProductById = async(productId) => {
  return axios.get(`${backendUrl}/product/${productId}`, axiosOptions)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw new Error(getErrorMessage(err));
    });
}

export const submitReport = async(product, reportReason, reportText) => {
  return axios.post(`${backendUrl}/product/report`, {
    product: product,
    reportReason: reportReason,
    reportText: reportText
  }, axiosOptions).then(() => {
    return;
  }).catch((err) => {
    console.error('Got err reporting', err);
    // We won't make a big deal of this error, because it isn't a huge deal.
    return null;
  });
}

export const isAmazonProduct = (product) => {
  return product && product.link && /^https:\/\/(www\.)(amazon|amzn)\./g.test(product.link);
}

export const shouldHidePrice = (product) => {
  return isAmazonProduct(product);
}

const convertLinkToWebsite = (link) => {
  return link.replace('https://', '').replace('http://', '').split('/')[0];
}

export const getWebsite = (product) => {
  return product.website ? product.website : convertLinkToWebsite(product.link)
}
