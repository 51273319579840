const countryOptions = {
  'global': {
    countryCode: 'global',
    country: 'Global',
    hoverText: 'Global Vendor'
  },
  'us': {
    countryCode: 'US',
    country: 'United States',
    hoverText: 'United States Vendor'
  },
  'ca': {
    countryCode: 'CA',
    country: 'Canada',
    hoverText: 'Canada Vendor'
  },
  'cn': {
    countryCode: 'CN',
    country: 'China',
    hoverText: 'China Vendor'
  },
   'sg': {
     countryCode: 'SG',
     country: 'Singapore',
     hoverText: 'Singapore Vendor'
   },
  /*
   'uk': {
     countryCode: 'GB',
     country: 'United Kingdom',
     hoverText: 'United Kingdom Vendor'
   }
  */
};

export const getAllCountryOptions = () => {
  return Object.keys(countryOptions).map((key) => {
    return { ...countryOptions[key], countryKey: key };
  })
}

export const getCountryCode = (location) => {
  return countryOptions[location] ? countryOptions[location] : countryOptions['global'];
}
