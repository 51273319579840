import React from 'react';

import { connectRefinementList } from 'react-instantsearch-dom';
import { Row, Col, Card, Form } from 'react-bootstrap';

const localStorage = window.localStorage;

function getKey(title, item) {
  if (typeof item.label === 'object') {
    return `${title}-${item.value}`;
  } else {
    return `${title}-${item.label}`;
  }
}

const RefinementList = ({
  items,
  currentRefinement,
  refine,
  visible,
  title,
  fixedOptions,
  formType,
  localStorageSource
}) => {
  if (!visible) {
    return null;
  }

  const adminFixedOptions = fixedOptions ? window.showAdmin ? [...fixedOptions, { label: 'unknown', value: 'unknown' }] : [...fixedOptions] : null

  const iterableItems = fixedOptions ? adminFixedOptions : items.sort((a, b) => { return b.label.localeCompare(a.label) });

  // return the DOM output
  return (
    <Card className="refinement_card">
      <div className="refinement_header">
        {title}
      </div>
      <Form.Group className="margin-bottom-0">
        {iterableItems.map( (item) => {
          const refined = currentRefinement.indexOf(item.value) >= 0
          return (
            <Row key={getKey(title, item)}>
              <Col>
                <Form.Group controlId={item.label}>
                  <Form.Check type={formType ? formType : "checkbox"}
                    label={item.label}
                    checked={fixedOptions ? refined : item.isRefined}
                    onChange={event => {
                      if (formType && formType === 'radio') {
                        refine(item.value !== 'ALL' ? [ item.value ] : []);
                      } else if (fixedOptions) {
                        const cur = new Set(currentRefinement);
                        if (event.target.checked) {
                          cur.add(item.value);
                        } else {
                          cur.delete(item.value);
                        }
                        if (localStorageSource) {
                          localStorage.setItem(localStorageSource, event.target.checked ? item.value : '');
                        }
                        refine([...cur]);
                      } else {
                        refine(item.value);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          );
        })}
      </Form.Group>
    </Card>
  )
};

const ProductRefinementList = connectRefinementList(RefinementList);

export default ProductRefinementList;
