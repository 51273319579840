import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { getCompatibilityTitle } from "../API/compatibility-information";
import CompatibilityModal from "./compatibility-modal";

let curSelectedCompatibility = null;

const ProductCompatibility = ({ recommendations, type }) => {
    const [showCompatibilityModal, setShowCompatibilityModal] = useState(false);

    if (recommendations.length === 0) {
        return null;
    }
    let mainContent = null;
    mainContent = recommendations.map((rec) => {
        return (
            <div
                key={rec.compatibilityType}
                className="compatibility-alert bg-alert border-light-primary text-light clickable pt-2 pb-2 pl-4 pr-4 small-border-radius"
                onClick={() => {
                    curSelectedCompatibility = rec.compatibilityType;
                    setShowCompatibilityModal(true);
                }}
            >
                <span>
                    {getCompatibilityTitle(rec.compatibilityType, type)}
                </span>
                <br />
                <span
                    style={{
                        marginLeft: "auto",
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "400",
                    }}
                >
                    <u>Click for more details</u>
                </span>
            </div>
        );
    });
    const warningRecs = recommendations.slice(0);
    return (
        <Card className="refinement_card">
            <div
                className="refinement_header"
                style={{
                    textAlign: "center",
                }}
            >
                Compatibility Recommendations
            </div>
            <CompatibilityModal
                showModal={showCompatibilityModal}
                setShowModal={setShowCompatibilityModal}
                dangerRecs={[]}
                warningRecs={warningRecs}
                defaultOpened={curSelectedCompatibility}
                type={type}
            />
            {mainContent}
            <div style={{ marginTop: "12px" }} />
        </Card>
    );
};

export default ProductCompatibility;
