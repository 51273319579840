import React, { useState } from 'react'
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap'

import { Link } from 'react-router-dom';
import Icon from '@mdi/react'
import { mdiContentCopy, mdiShareVariant, mdiHistory, mdiUndoVariant } from '@mdi/js';

import ButtonGroupFilter from './button-group-filter';
import ConfirmButton from './confirm-button';
import HistoryModal from './history-modal';
import ShareModal from './share-modal';
import { baseLocalStorageSource, baseOptions, defaultBaseSelection } from '../API/user-preferences';
import useWindowDimensions from './useWindowDimensions';
import { getHistory } from '../API/history';

const localStorage = window.localStorage;

const BuilderOperations = ({ resetProducts, copyBuildLink, getBuildAsText, getBuildAsMd, copyToClipboard, buildLink, setBaseFilter }) => {
  const { width } = useWindowDimensions();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const storedBase = localStorage.getItem(baseLocalStorageSource);
  const defaultBase = storedBase && storedBase !== 'null' ? storedBase : defaultBaseSelection;

  const history = getHistory();
  const firstHistory = history.length > 0 ? history[history.length - 1] : null;

  const shouldShowRestoreButton = firstHistory && window.location.pathname.length <= 1
  const shouldHideExtraProductReliantButtons = (window.location.pathname.length <= 1 && width < 576) || shouldShowRestoreButton;

  return (
    <React.Fragment>
      <HistoryModal showHistoryModal={showHistoryModal} setShowHistoryModal={setShowHistoryModal} />
      <ShareModal showShareModal={showShareModal} setShowShareModal={setShowShareModal} getBuildAsText={getBuildAsText} getBuildAsMd={getBuildAsMd} copyToClipboard={copyToClipboard} />
      <Row>
        <Col md={6}>
          <div className="button-group-margins" />
          <ButtonGroupFilter
            label="Base"
            defaultSelection={defaultBase}
            options={baseOptions}
            selectedCallback={(selected) => {
              setBaseFilter(selected);
              localStorage.setItem(baseLocalStorageSource, selected)
            }}
            fillHeight={true}
            showImage={true}
          />
          <div className="button-group-margins" />
        </Col>
        <Col md={6}>
          <Row className="button-group-margins">
            <Col sm={12}>
              <div className="build-link">
                <InputGroup>
                  <FormControl
                    id="link"
                    placeholder="Select products to create a link to your build"
                    aria-label="link"
                    aria-describedby="basic-addon2"
                    value={buildLink ? buildLink : ""}
                    readOnly
                    style={{paddingLeft: '22px'}}
                  />
                  {
                    buildLink ?
                      (
                        <InputGroup.Append>
                          <ConfirmButton onClick={copyBuildLink} confirmtext={''}>
                            <Icon path={mdiContentCopy} />
                          </ConfirmButton>
                        </InputGroup.Append>
                      )
                      :
                      null
                  }
                </InputGroup>
              </div>
            </Col>

            <Col sm={shouldShowRestoreButton ? 6 : 4} className="button-group-margins">
              <div  className="horizontal-center padding-medium">
                <Button variant='secondary' className="btn-small" size="sm" onClick={() => {setShowHistoryModal(true)}}>
                  <Icon size={.7} path={mdiHistory}/> History
                </Button>
              </div>
            </Col>
            {
              shouldShowRestoreButton ?
              (
                <Col sm={6} className="button-group-margins">
                  <div  className="horizontal-center padding-medium">
                    <Link to={ firstHistory.linkId } >
                      <Button variant='secondary' className="btn-large border-light-primary" style={{ borderWidth: 'medium', minWidth: '200px' }} size="lg">
                        <Icon size={.7} path={mdiUndoVariant}/> Restore Last Build
                      </Button>
                    </Link>
                  </div>
                </Col>
              )
              :
              null
            }
            { shouldHideExtraProductReliantButtons ?
                null
                :
                (
                  <Col sm={4} className="button-group-margins">
                    <div className="horizontal-center padding-medium">
                      <Button variant='secondary' className="btn-small" size="sm" onClick={() => { setShowShareModal(true) }}>
                        <Icon size={.6} path={mdiShareVariant} /> Share
                        </Button>
                    </div>
                  </Col>
                )
            }
            { shouldHideExtraProductReliantButtons ?
                null
                :
                (
                  <Col sm={4} className="button-group-margins">
                      <div  className="horizontal-center padding-medium">
                        <ConfirmButton variant='secondary' className="btn-small" size="sm" onClick={resetProducts} confirmtext={' Reset'}>
                          <Icon size={.7} path={mdiUndoVariant} /> Reset Build
                        </ConfirmButton>
                      </div>
                  </Col>
                )
            }
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}


export default BuilderOperations
