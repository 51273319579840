import algoliasearch from "algoliasearch/lite";
import {
    algoliaAppId,
    algoliaApiKey,
    backendUrl,
    publicApiKey,
} from "../constants";
import { getErrorMessage } from "./common";

const frontendSearchClient = algoliasearch(algoliaAppId, algoliaApiKey);
// frontendSearhClient has its own in-browser cache,
// but we need to implement our own for the query to our backend to be cached in-browser
// so we just use that cache for both, and we could probably disable frontendSearchClients cache
// without this the one query we make on our own does not get cached on the frontend until it gets made directly to Algolia
let queryMemCache = {};
let useBackendCache = true;

function extractQueryKey(requests) {
    // concat all the requests to get a key for this query
    var queryKey = "";
    requests.forEach((req) => {
        queryKey += JSON.stringify(req);
    });
    return queryKey;
}

// We should weight the pros/cons of when to use the cached client.
// We are playing Algolia for items stored vs queries (whichever is greater).
// So ideally we want our queries == number of items in Algolia.
// For now we will _NOT_ use the Cached Client for -
// 1. When doing explicit searching
// 2. Options are provided
// We can adjust these as needed.  (Lookup most searched queries on Algolia and move them here when needed).
function shouldUseCachedClient(requests, requestOptions) {
    try {
        if (!useBackendCache || requestOptions) {
            return false;
        }
        if (requests && requests.length > 0) {
            // Just check if any have an explicit query.
            for (let i = 0; i < requests.length; i++) {
                if (requests[i]) {
                    if (
                        requests[i].params &&
                        requests[i].params.query &&
                        requests[i].params.query.length > 0
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    } catch (err) {
        return false;
    }
}

async function search(
    requests,
    backendRoute,
    loading,
    setLoading,
    setAlert,
    algoliaCall,
    requestOptions
) {
    if (shouldUseCachedClient(requests, requestOptions)) {
        const queryKey = extractQueryKey(requests);
        // if we have seen this exact query before use the results we have in memory
        if (queryMemCache[queryKey]) {
            setTimeout(() => {
                if (loading) {
                    setLoading(false);
                }
            }, 0);
            return queryMemCache[queryKey];
        }
        // Query Backend.
        try {
            const results = await fetch(backendUrl + backendRoute, {
                method: "post",
                headers: {
                    "x-api-key": publicApiKey,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ requests }),
            });
            const resJson = await results.json();
            queryMemCache[queryKey] = resJson;
            setTimeout(() => {
                if (loading) {
                    setLoading(false);
                }
            }, 0);
            return resJson;
        } catch (e) {
            console.error("Cannot Query Backend.  Falling Back To Algolia.", e);
            useBackendCache = false;
            delete queryMemCache[queryKey];
        }
    }
    try {
        const results = await algoliaCall(requests, requestOptions);
        setTimeout(() => {
            if (loading) {
                setLoading(false);
            }
        }, 0);
        return results;
    } catch (e) {
        console.error("Cannot Query Algolia.");
        setAlert(getErrorMessage(e));
        setTimeout(() => {
            if (loading) {
                setLoading(false);
            }
        }, 0);
        return [];
    }
}

function CachedSearchClient(loading, setLoading, setAlert) {
    return {
        ...frontendSearchClient,
        search(requests, requestOptions) {
            return search(
                requests,
                "/cache-search",
                loading,
                setLoading,
                setAlert,
                frontendSearchClient.search,
                requestOptions
            );
        },
        searchForFacetValues(requests, requestOptions) {
            return search(
                requests,
                "/cache-sffv",
                loading,
                setLoading,
                setAlert,
                frontendSearchClient.searchForFacetValues,
                requestOptions
            );
        },
    };
}

export default CachedSearchClient;
