import React from 'react'
import { Badge } from 'react-bootstrap'
import { sizeOptions, switchOptions, hotswapOptions } from '../API/user-preferences';

const ProductBadge = ({ hit }) => {
  const badges = [];
  if (hit.sizeCompatibility) {
    const sizeOption = sizeOptions.find((sizeOption) => { return sizeOption.value === hit.sizeCompatibility })
    if (sizeOption && sizeOption.badgeText) {
      badges.push({ badgeText: sizeOption.badgeText, badgeColor: sizeOption.badgeColor });
    }
  }
  if (hit.switchCompatibility) {
    const switchOption = switchOptions.find((switchOption) => { return switchOption.value === hit.switchCompatibility })
    if (switchOption && switchOption.badgeText) {
      badges.push({ badgeText: switchOption.badgeText, badgeColor: switchOption.badgeColor });
    }
  }
  if (hit.pcbCompatibility) {
    const hotswapOption = hotswapOptions.find((hotswapOption) => { return hotswapOption.value === hit.pcbCompatibility })
    if (hotswapOption && hotswapOption.badgeText) {
      badges.push({ badgeText: hotswapOption.badgeText, badgeColor: hotswapOption.badgeColor });
    }
  }
  if (badges.length > 0) {
    return badges.map(({ badgeColor, badgeText }) => {
      return <Badge className="ml-1" key={badgeText} pill variant={badgeColor} style={{ lineHeight: '0.9' }}>{badgeText}</Badge>
    })
  }
  else {
    return null;
  }
}

export default ProductBadge
