import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import { Alert, Col, Row, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Mustache from "mustache";

import { getInitialProducts, removeProduct } from "../API/build";
import {
    baseLocalStorageSource,
    defaultBaseSelection,
} from "../API/user-preferences";
import { shouldHidePrice, getWebsite } from "../API/product";

import useSticky from "../Components/use-sticky.js";

import BuilderInfoBox from "../Components/builder-info-box";
import BuilderOperations from "../Components/builder-operations";
import BuilderTable from "../Components/builder-table";
import Container from "react-bootstrap/Container";
import NextStepsModal from "../Components/next-steps-modal";

import ScrollToTopOnMount from "../Components/scroll-to-top-mount";

import "../Styles/keyboard-builder.css";

import buildTxt from "../Templates/build-text.mustache";
import buildMd from "../Templates/build-md.mustache";
import { ProductTypeToNameMap } from "../constants";

const copyToClipboard = (
    input,
    defaultValue = "https://keyboardpartpicker.com"
) => {
    /* Get the text field */
    // Create an auxiliary hidden input
    let aux = document.createElement("textarea");
    // Get the text from the element passed into the input
    aux.value = input || defaultValue;
    // Append the aux input to the body
    document.body.appendChild(aux);
    // Highlight the content
    aux.select();
    // Execute the copy command
    document.execCommand("copy");
    // Remove the input from the body
    document.body.removeChild(aux);
};

const localStorage = window.localStorage;

const KeyboardBuilderPage = ({ history, location }) => {
    let { browserLinkId } = useParams();
    const { isSticky, element } = useSticky();

    const showNextSteps =
        location && location.state && location.state.showNextSteps;

    const [showingFullPrice, setShowingFullPrice] = useState(true);
    const [linkId, setLinkId] = useState(browserLinkId);

    const [products, setProducts] = useState([]);
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(true);
    const [price, setPrice] = useState(0);
    const builderInfoElement = useRef(null);

    const storedBase = localStorage.getItem(baseLocalStorageSource);
    const defaultBase =
        storedBase && storedBase !== "null" ? storedBase : defaultBaseSelection;
    const [baseFilter, setBaseFilter] = useState(defaultBase);

    useEffect(() => {
        let price = 0;
        let showingFullPrice = true;
        for (const product of products) {
            if (shouldHidePrice(product)) {
                showingFullPrice = false;
            } else {
                price += parseFloat(product.price) * product.quantity;
            }
        }
        setShowingFullPrice(showingFullPrice);
        setPrice(price);
        setAlert(null);
    }, [products]);

    useEffect(() => {
        setLinkId(browserLinkId);
    }, [browserLinkId]);

    useEffect(() => {
        if (linkId) {
            sessionStorage.setItem("buildLink", linkId);
        } else {
            sessionStorage.setItem("buildLink", "");
        }
        const bootstrap = async () => {
            if (linkId) {
                // The error message that shows in staging/dev from the first-build flow does not show in prod because those id's exist there.
                const results = await getInitialProducts(linkId);
                setProducts(results.products);
                setLoading(false);
                sessionStorage.setItem(
                    "products",
                    JSON.stringify(results.products)
                );
                if (results.message && !showNextSteps) {
                    setAlert(results.message);
                }
            } else {
                setLoading(false);
                setProducts([]);
                sessionStorage.setItem("products", []);
            }
        };
        bootstrap();
    }, [linkId]);

    const resetProducts = () => {
        setLinkId(null);
        sessionStorage.setItem("buildLink", null);
        setProducts([]);
        sessionStorage.setItem("products", []);
        history.push("/");
    };

    // TODO: Sync this with app-layout and the backend config.
    const productRowData = [
        {
            path: "prebuilt",
            label: "Prebuilt",
            shouldShow: baseFilter === "all" || baseFilter === "prebuilt",
        }, //baseOption === 'all' || baseOption. },
        {
            path: "kit",
            label: "DIY Kit",
            shouldShow: baseFilter === "all" || baseFilter === "kit",
        },
        {
            path: "case",
            label: "Case",
            shouldShow: baseFilter === "all" || baseFilter === "custom",
        },
        {
            path: "pcb",
            label: "PCB",
            shouldShow: baseFilter === "all" || baseFilter === "custom",
        },
        {
            path: "plate",
            label: "Plate",
            shouldShow: baseFilter === "all" || baseFilter === "custom",
        },
        { path: "cap", label: "Keycaps", showAddMore: true },
        { path: "switch", label: "Switches", showAddMore: true },
        {
            path: "stabilizer",
            label: "Stabilizers",
            showAddMore: true,
            shouldShow: baseFilter !== "prebuilt",
        },
        {
            label: "Extras",
            path: "extras",
            children: [
                { path: "cable", label: "Cables" },
                { path: "deskmat", label: "Deskmats" }, // solder suctor, soldering gun, soldering stand, solder, solder sponge
            ],
        },
        {
            label: "Tools",
            path: "tools",
            children: [
                { path: "lube", label: "Lube Essentials" },
                { path: "switch-essentials", label: "Switch Essentials" }, // switch lube station, switch films, switch puller, keycap puller, switch springs
                { path: "solder-essentials", label: "Soldering Essentials" },
            ],
        },
    ];

    const copyBuildLink = () => {
        copyToClipboard(document.getElementById("link").value);
    };

    const getProductLink = (product) => {
        return `${window.location.protocol}//${window.location.host}/product/${product.id}`;
    };

    const getCopyParams = () => {
        let shouldShowPriceWarning = false;
        const productData = products.map((product) => {
            const hidePrice = shouldHidePrice(product);
            shouldShowPriceWarning = shouldShowPriceWarning || hidePrice;
            const website = getWebsite(product).replace("www.", "");
            return {
                type: ProductTypeToNameMap[product.type],
                name: product.name,
                link: getProductLink(product),
                variantTitle: product.variantTitle,
                priceText: hidePrice
                    ? `${website}**`
                    : `$${(
                          Math.round(parseFloat(product.price) * 100) / 100
                      ).toFixed(2)} @ ${website}`,
            };
        });
        return {
            priceWarning: shouldShowPriceWarning
                ? `**\n**Prices of products from Amazon are not included`
                : ``,
            productData: productData,
        };
    };

    const getBuildAsText = () => {
        const { priceWarning, productData } = getCopyParams();
        return Mustache.render(buildTxt, {
            link: document.getElementById("link").value,
            products: productData,
            totalPrice: (Math.round(parseFloat(price) * 100) / 100).toFixed(2),
            priceWarning: priceWarning,
        });
    };

    const getBuildAsMd = () => {
        const { priceWarning, productData } = getCopyParams();
        return Mustache.render(buildMd, {
            link: document.getElementById("link").value,
            products: productData,
            totalPrice: (Math.round(parseFloat(price) * 100) / 100).toFixed(2),
            priceWarning: priceWarning,
        });
    };

    const navigateToListing = () => {
        setLoading(true);
    };

    const removeProductAndFromHistory = async (product) => {
        const result = await removeProduct(products, product);
        if (result.link) {
            history.push(result.link);
        } else {
            setAlert(result.message);
        }
    };

    const buildLink = linkId && window.document.location.href;

    /*const NextSteps = showNextSteps ? (
    <div align="center" style={{ fontSize: '1.20rem' }}>
      <div style={{ marginTop: '12px' }} />
      <Link className="clickable" to="/first-build"><Button variant="secondary" style={{ fontSize: '.8rem' }}>Redo Questionnaire</Button></Link>
      <br />
      <div style={{ marginTop: '12px' }}>We have prefilled tools and filters to help guide your build.
      <br />
      Click <b>SELECT</b> below to pick parts for your custom build.
      <br />
      </div>
      <hr className="light-separator" />
    </div>
  ) : null;*/

    const BuildGeneratorLink =
        window.location.pathname === "/" ? (
            <div style={{ fontSize: "1.20rem", marginTop: "20px" }}>
                <div className="horizontal-center builder_table_container">
                    <Alert className="border-light-primary padding-medium bg-alert small-border-radius text-center p-3 mb-0y">
                        <span className="">
                            Building your first{" "}
                            <span className="avoidwrap">custom keyboard?</span>{" "}
                        </span>
                        <span className="avoidwrap">
                            {" "}
                            Click{" "}
                            <Link
                                className="clickable text-light"
                                to="/first-build"
                            >
                                <u>
                                    <b>here</b>
                                </u>
                            </Link>{" "}
                            to get started.
                        </span>
                    </Alert>
                </div>
            </div>
        ) : null;

    const paddingTop =
        builderInfoElement &&
        builderInfoElement.current &&
        builderInfoElement.current.getBoundingClientRect &&
        isSticky
            ? Math.round(
                  builderInfoElement.current.getBoundingClientRect().height
              )
            : 0;
    return (
        <React.Fragment>
            <Helmet>
                <title>Build Your Keyboard | Keyboard Part Picker</title>
            </Helmet>
            <Container fluid>
                <ScrollToTopOnMount />
                <NextStepsModal shouldShowNextSteps={showNextSteps} />
                <div className="keyboard-builder">
                    <Row className="builder_top_container">
                        <Col sm={12}>
                            <div className="horizontal-center builder_table_container">
                                {BuildGeneratorLink}
                                <BuilderOperations
                                    setBaseFilter={setBaseFilter}
                                    resetProducts={resetProducts}
                                    copyBuildLink={copyBuildLink}
                                    getBuildAsText={getBuildAsText}
                                    getBuildAsMd={getBuildAsMd}
                                    copyToClipboard={copyToClipboard}
                                    buildLink={buildLink}
                                />
                            </div>
                        </Col>
                    </Row>
                    <span ref={element} />
                    <div
                        style={{
                            paddingTop: `${paddingTop}px`,
                        }}
                    />
                    <Row
                        ref={builderInfoElement}
                        className={
                            isSticky
                                ? `builder_top_container sticky-top`
                                : `builder_top_container`
                        }
                    >
                        <Col sm={12}>
                            <div
                                className="horizontal-center builder_table_container"
                                style={{ padding: "2px 0px" }}
                            >
                                <BuilderInfoBox
                                    price={price}
                                    products={products}
                                    loading={loading}
                                    showingFullPrice={showingFullPrice}
                                />
                            </div>
                        </Col>
                    </Row>
                    <span></span>
                    <Row>
                        <Col sm={12}>
                            <div className="horizontal-center builder_table_container">
                                {loading ? (
                                    <Spinner
                                        className="horizontal-center spinner"
                                        animation="border"
                                    />
                                ) : (
                                    <React.Fragment>
                                        <Alert
                                            key="build-alert"
                                            className="build-alert"
                                            show={alert != null}
                                            variant="warning"
                                        >
                                            {alert}
                                        </Alert>
                                        <BuilderTable
                                            productRowData={productRowData}
                                            navigateToListing={
                                                navigateToListing
                                            }
                                            products={products}
                                            removeProduct={
                                                removeProductAndFromHistory
                                            }
                                            setLoading={setLoading}
                                            setAlert={setAlert}
                                            selectedBase={baseFilter}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default withRouter(KeyboardBuilderPage);
