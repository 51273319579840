import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faInstagram,
    faDiscord,
    faReddit,
} from "@fortawesome/free-brands-svg-icons";
import useWindowDimensions from "./useWindowDimensions";
import Logo from "../Assets/kbpp.png";
import { Nav, Navbar, Row, Col, Alert } from "react-bootstrap";
import {
    withRouter,
    useHistory,
    Link,
    useLocation,
    matchPath,
} from "react-router-dom";
import useAnalytics from "./analytics";
import useSticky from "../Components/use-sticky.js";
import Search from "./search";
import { Helmet } from "react-helmet";
import { isProd } from "../constants";

const NavigationBar = ({ theme, searchState, setSearchState }) => {
    const navBarRef = useRef(null);
    const { isSticky, element } = useSticky();
    const { width } = useWindowDimensions();
    const history = useHistory();
    const location = useLocation();
    const typeMatch = matchPath(location.pathname, {
        path: "/listing/:type",
    });

    const isSmallScreen = width <= 780;

    const goToBuildPage = (e) => {
        const buildLink = sessionStorage.getItem("buildLink");
        if (buildLink && buildLink !== "null" && buildLink !== "undefined") {
            history.push(`/build/${buildLink}`);
        } else {
            history.push("/");
        }
        e.preventDefault();
    };

    // This isn't actually the height of the navbar, but just a chosen spot to start scrolling the searchbar with the page
    const shadowNavbarHeight = typeMatch ? "120px" : "0px";
    const showLogo = !(isSmallScreen && isSticky && typeMatch);

    return (
        <React.Fragment>
            {window.location.pathname === "/" ? (
                <Alert
                    variant={"success"}
                    className="m-0 p-2 text-center"
                    style={{ borderRadius: "0px", fontSize: "14px" }}
                >
                    Welcome to the relaunched KeyboardPartPicker.com!
                    <br />
                    We are excited to bring you a fresh, new website experience.
                </Alert>
            ) : null}
            <div
                ref={element}
                style={{ position: "absolute", marginTop: shadowNavbarHeight }}
            ></div>
            <div style={{ height: shadowNavbarHeight }}></div>
            <Navbar
                ref={navBarRef}
                fixed={typeMatch ? "top" : ""}
                expand="md"
                variant={theme}
                className="bg-light py-1"
            >
                {showLogo ? (
                    <Navbar.Brand onClick={goToBuildPage} className="brand">
                        <img alt="logo" className="logo" src={Logo} />
                        <span className="site-name">Keyboard Part Picker</span>
                    </Navbar.Brand>
                ) : null}
                <Nav className="nav-content ml-auto w-100 nav-search">
                    <Search
                        searchState={searchState}
                        setSearchState={setSearchState}
                    />
                </Nav>
            </Navbar>
        </React.Fragment>
    );
};

const Footer = () => {
    const socials = [
        {
            icon: <FontAwesomeIcon icon={faInstagram} size="1x" />,
            social: "https://www.instagram.com/keyboardpartpicker/",
        },
        {
            icon: <FontAwesomeIcon icon={faTwitter} size="1x" />,
            social: "https://twitter.com/kbpartpicker",
        },
        {
            icon: <FontAwesomeIcon icon={faDiscord} size="1x" />,
            social: "https://discord.gg/qZAxDsRkMs",
        },
        {
            icon: <FontAwesomeIcon icon={faReddit} size="1x" />,
            social: "https://www.reddit.com/r/KeyboardPartPicker/",
        },
    ];
    return (
        <div className="footer">
            <Row noGutters>
                <Col sm={6}>
                    <div className="footer-logo">
                        <img className="logo" alt="logo" src={Logo} />
                        <span
                            className="site-name"
                            style={{ marginTop: "32px" }}
                        >
                            Keyboard Part Picker
                        </span>
                    </div>
                    <div className="footer-socials mt-3">
                        {socials.map((social, id) => {
                            return (
                                <a
                                    key={id}
                                    href={social.social}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="social-icon text-primary"
                                >
                                    {social.icon}
                                </a>
                            );
                        })}
                    </div>
                </Col>
                <Col sm={6}>
                    <div className="footer-links">
                        <Link
                            className="clickable text-primary"
                            to="/first-build"
                        >
                            <u>First Build Questionnaire</u>
                        </Link>
                        <br />
                        <Link className="clickable text-primary" to="/faq">
                            <u>FAQ</u>
                        </Link>
                        <br />
                        <Link className="clickable text-primary" to="/tos">
                            <u>Terms of Service</u>
                        </Link>
                        <br />
                        <Link
                            className="clickable text-primary"
                            to="/disclaimers"
                        >
                            <u>Disclosures and Disclaimers</u>
                        </Link>
                        <br />
                        <Link
                            className="clickable text-primary"
                            to="/privacy-policy"
                        >
                            <u>Privacy Policy</u>
                        </Link>
                        <br />
                        <Link
                            className="clickable text-primary"
                            to="/contact-us"
                        >
                            <u>Contact Us</u>
                        </Link>
                        <br />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const AppLayout = ({ theme, searchState, setSearchState, children }) => {
    useAnalytics();
    return (
        <React.Fragment>
            {!isProd && (
                <Helmet>
                    <script
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6882888432022646"
                        crossOrigin="anonymous"
                    ></script>
                </Helmet>
            )}
            <NavigationBar
                theme={theme}
                searchState={searchState}
                setSearchState={setSearchState}
            />
            <div className="MainContent">{children}</div>
            <Footer />
        </React.Fragment>
    );
};

export default withRouter(AppLayout);
