export const getErrorMessage = (err) => {
  let errorMessage = "Something Went Wrong :("
  if (err && err.message) {
    errorMessage = err.message;
  }
  // Prioritize message from API.
  if (err.response && err.response.data && err.response.data.message) {
    errorMessage = err.response.data.message;
  }
  return errorMessage;
}