import { useEffect, useState, useRef } from "react"
import _ from 'lodash';

function useSticky() {
  const [isSticky, setSticky] = useState(false)
  const element = useRef(null)

  const handleScroll = () => {
    if (!element || !element.current) {
      return;
    }
    element.current.getBoundingClientRect().bottom <= 0 ?
      setSticky(true) :
      setSticky(false)
  }

  useEffect(() => {
    window.addEventListener("scroll", _.throttle(handleScroll, 20))
    return () => {
      window.removeEventListener("scroll", _.throttle(handleScroll, 20))
    }
  }, [])

  return { isSticky, element }
}

export default useSticky
