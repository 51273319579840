import React, { Suspense, useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Form,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import ProductImageView from "./product-image-view";
import Icon from "@mdi/react";
import { mdiTrashCanOutline, mdiFlagVariantOutline } from "@mdi/js";
import useWindowDimensions from "./useWindowDimensions";
import { addProduct } from "../API/build";
import { getWebsite } from "../API/product";
import { shouldHidePrice, isAmazonProduct } from "../API/product";
import { useHistory } from "react-router-dom";
import ReportModal from "./report-modal";
import ConfirmButton from "./confirm-button";
import CountryIcon from "./country-icon";
import ProductBadge from "./product-badge";
const EditModal = React.lazy(() => import("./edit-modal"));

export const HitName = ({ hit }) => {
    return (
        <span>
            {hit.name} <ProductBadge hit={hit} />
        </span>
    );
};

const ProductRow = ({
    hit,
    insights,
    removeProduct,
    showSelectButton,
    setAlert,
    hideFloatingButtons,
    providedHistory = undefined,
}) => {
    const history = providedHistory || useHistory();
    const [quantity, setQuantity] = useState(hit.quantity);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {
        async function updateQuantity() {
            if (
                !history ||
                !quantity ||
                !hit.quantity ||
                hit.quantity === quantity
            ) {
                return;
            }
            let curProducts = sessionStorage.getItem("products");
            try {
                curProducts = JSON.parse(sessionStorage.getItem("products"));
            } catch (e) {
                curProducts = [];
            }
            const result = await addProduct(
                curProducts,
                hit,
                false,
                true,
                parseInt(quantity)
            );
            if (result.link) {
                history.push(result.link);
            } else {
                setAlert(result.message);
            }
        }
        updateQuantity();
    }, [quantity]);

    const selectProduct = async (product) => {
        let curProducts = sessionStorage.getItem("products");
        try {
            curProducts = JSON.parse(sessionStorage.getItem("products"));
        } catch (e) {
            curProducts = [];
        }
        const result = await addProduct(curProducts, product, false, true);
        if (!result.link) {
            console.error("Failed to add the product");
        } else {
            history.push(result.link);
        }
    };

    const [hovering, setHovering] = useState(false);
    const { width } = useWindowDimensions();

    let existingProducts = [];
    try {
        existingProducts = JSON.parse(sessionStorage.getItem("products"));
    } catch (e) {
        // do nothing
    }
    const alreadyAdded =
        existingProducts &&
        existingProducts.filter((product) => {
            return product.id === hit.id;
        }).length > 0;
    const link = hit.affiliateLink ? hit.affiliateLink : hit.link;
    const variantText =
        hit.variantTitle &&
        hit.variantTitle.length > 0 &&
        hit.variantTitle !== "Default Title" ? (
            <span>
                <span className="hit_title_bold_subtext"> | </span>
                {hit.variantTitle}
            </span>
        ) : null;
    const groupBuyText = hit.groupBuy ? (
        <span className="hit_title_bold_subtext">Pre-Order | </span>
    ) : null;

    const cleanedWebsite = getWebsite(hit);

    const hidePrice = shouldHidePrice(hit);

    const linkText = isAmazonProduct(hit) ? (
        <span>
            Sold by Amazon at{" "}
            <a
                target="_blank"
                rel="noreferrer"
                className="hit_title_bold_subtext"
                href={hit.affiliateLink}
            >
                {hit.affiliateLink}
            </a>{" "}
            <CountryIcon location={hit.location} />
        </span>
    ) : (
        <span>
            Sold by{" "}
            <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="hit_title_bold_subtext"
            >
                {cleanedWebsite}
            </a>{" "}
            <CountryIcon location={hit.location} />
        </span>
    );

    let updatedDate = "N/A";
    if (hit && hit.updatedDate) {
        // Grab YYYY-MM-DD from ISO date
        updatedDate = hit.updatedDate.split("T")[0];
    }

    const priceComponent = hit.price ? (
        <React.Fragment>
            <span className="hit_price">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 500 }}
                    overlay={<Tooltip>Last Updated: {updatedDate}</Tooltip>}
                >
                    <span className="hit_price_value">
                        {hidePrice
                            ? ``
                            : `$${(
                                  Math.round(parseFloat(hit.price) * 100) / 100
                              ).toFixed(2)}`}
                    </span>
                </OverlayTrigger>
            </span>
            <label className="lighter" style={{ fontSize: "14px" }}>
                {alreadyAdded && !showSelectButton ? "Already in Build" : null}
            </label>
        </React.Fragment>
    ) : (
        <React.Fragment></React.Fragment>
    );
    return (
        <div
            className="hit_placeholder"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <Suspense fallback="">
                <EditModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    initialProduct={hit}
                />
            </Suspense>

            <ReportModal
                showReportModal={showReportModal}
                setShowReportModal={setShowReportModal}
                product={hit}
            />
            {(hovering || width < 768) && !hideFloatingButtons ? (
                <Row className="product_floating_buttons_container">
                    <Col xs={8} md={10}></Col>
                    <Col xs={4} md={2}>
                        <span className="horizontal-right">
                            <Icon
                                path={mdiFlagVariantOutline}
                                title="Report Product for Inaccuracies or Concerns"
                                size={1}
                                color="red"
                                className="clickable"
                                onClick={() => {
                                    setShowReportModal(true);
                                }}
                            />
                            {removeProduct ? (
                                <Icon
                                    path={mdiTrashCanOutline}
                                    title="Remove Product from my Build"
                                    size={1}
                                    color="black"
                                    className="clickable"
                                    onClick={() => {
                                        removeProduct(hit);
                                    }}
                                />
                            ) : null}
                        </span>
                    </Col>
                </Row>
            ) : null}
            <Row className="align-items-center hit_item" key={hit.id}>
                <Col xs={12} md={2}>
                    <div className="hit_image_preview_container">
                        <ProductImageView
                            link={link}
                            hit={hit}
                            variantText={variantText}
                            groupBuyText={groupBuyText}
                            images={hit.images}
                            website={cleanedWebsite}
                            onClick={() => {
                                if (insights) {
                                    insights("clickedObjectIDsAfterSearch", {
                                        eventName: "Opened Product Image",
                                    });
                                }
                            }}
                        />
                    </div>
                </Col>
                <Col xs={12} md={5} xl={6}>
                    <div className="hit_title">
                        <a
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                if (insights) {
                                    insights("clickedObjectIDsAfterSearch", {
                                        eventName: "Opened Product Link",
                                    });
                                }
                            }}
                        >
                            <HitName hit={hit} />
                        </a>
                        <Row className="hit_title_subtext">
                            <Col md={12}>
                                <span>
                                    {groupBuyText}
                                    {hit.vendor}
                                    {variantText}
                                </span>
                            </Col>
                        </Row>
                        {link ? (
                            <Row className="hit_title_soldby">
                                <Col md={12}>{linkText}</Col>
                            </Row>
                        ) : null}
                        {hidePrice ? (
                            <Row className="hit_title_subtext text-red">
                                <Col md={12}>
                                    <span
                                        className="text-primary"
                                        style={{ fontWeight: 600 }}
                                    >
                                        **Click product to see price and
                                        availability
                                    </span>
                                </Col>
                            </Row>
                        ) : null}
                        {!hit.available ? (
                            <Row className="hit_title_subtext text-red">
                                <Col md={12}>
                                    <span>Out of Stock</span>
                                </Col>
                            </Row>
                        ) : null}
                    </div>
                </Col>
                <Col xs={12} md={3} xl={2}>
                    <Row>
                        <Col md={8}>{priceComponent}</Col>
                        <Col md={4}>
                            {hit && hit.quantity ? (
                                <Form.Group
                                    className="mobile-center"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label className="qty-label">
                                        Qty
                                    </Form.Label>
                                    <Form.Control
                                        className="qty-input"
                                        type="number"
                                        placeholder="Qty"
                                        value={quantity}
                                        min={1}
                                        max={200}
                                        onClick={(e) => {
                                            e.target.select();
                                        }}
                                        onChange={(e) => {
                                            parseFloat(e.target.value) > 0
                                                ? setQuantity(e.target.value)
                                                : null;
                                        }}
                                    />
                                </Form.Group>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={2}>
                    <div className="horizontal-center">
                        {!showSelectButton ? (
                            <ConfirmButton
                                confirmtext="Adding"
                                confirmsymbol="loading"
                                className="hit_btn"
                                style={{ height: "40px" }}
                                onClick={() => {
                                    if (insights) {
                                        insights(
                                            "convertedObjectIDsAfterSearch",
                                            {
                                                eventName:
                                                    "Add Product To Build",
                                            }
                                        );
                                    }
                                    selectProduct(hit);
                                }}
                            >
                                <div style={{ backgroundColor: "transparent" }}>
                                    ADD
                                </div>
                            </ConfirmButton>
                        ) : (
                            <Button
                                className="hit_btn"
                                onClick={() => {
                                    window.open(link, "_blank");
                                }}
                            >
                                BUY
                            </Button>
                        )}
                    </div>
                    {window.showAdmin ? (
                        <Button
                            onClick={() => {
                                setShowEditModal(true);
                            }}
                        >
                            EDIT
                        </Button>
                    ) : null}
                </Col>
            </Row>
        </div>
    );
};

export default ProductRow;
