import React from "react";

import { Row, Col, Button } from "react-bootstrap";
import ProductRow from "./product-row";
import { Link } from "react-router-dom";
import Progress from "./progress";
import useWindowDimensions from "./useWindowDimensions";
import useSticky from "./use-sticky";

const TypeLabel = ({ name, navigate, type, showAddMore }) => {
    return (
        <div>
            <Link
                className="type_label"
                to={`/listing/${type}`}
                onClick={() => navigate()}
            >
                <b>{name}</b>
            </Link>
            <br />
            {showAddMore ? (
                <Link
                    className="add_more_btn"
                    to={`/listing/${type}`}
                    onClick={() => navigate()}
                    style={{ fontWeight: "600" }}
                >
                    Add More
                </Link>
            ) : null}
        </div>
    );
};

const BuilderTable = ({
    productRowData,
    navigateToListing,
    products,
    removeProduct,
    setAlert,
    selectedBase,
}) => {
    const { isSticky, element } = useSticky();
    const { width } = useWindowDimensions();
    let allRows = [];
    productRowData.forEach((row) => {
        if (!row.children) {
            if (row.shouldShow === undefined) {
                allRows.push(row);
            } else {
                // we have a boolean of if we should show this type or not.
                if (row.shouldShow) {
                    allRows.push(row);
                } else {
                    // If we do have the type in our existing list of products, we should show it anyways, regardless of the shouldShow variable.
                    const matchingFilters = products.filter((p) => {
                        return p.type === row.path;
                    });
                    if (matchingFilters.length > 0) {
                        allRows.push(row);
                    }
                }
            }
        } else {
            row.children.forEach((childRow) => {
                if (
                    products.filter((p) => {
                        return p.type === childRow.path;
                    }).length > 0
                ) {
                    allRows.push(childRow);
                }
            });
            allRows.push(row);
        }
    });

    const shouldShowProgressAboveProducts = width < 1250;
    return (
        <div>
            <span
                key="builder-table"
                className="padding-medium"
                style={
                    shouldShowProgressAboveProducts
                        ? {}
                        : {
                              width: "calc(100% - 300px)",
                              float: "left",
                          }
                }
            >
                {
                    shouldShowProgressAboveProducts ? (
                        <Row>
                            <Col xs={12} className="product_row">
                                <div
                                    style={{
                                        marginLeft: "calc(50% - 125px)",
                                        marginTop: "-50px",
                                        height: "210px",
                                    }}
                                >
                                    <Progress
                                        selectedBase={selectedBase}
                                        products={products}
                                        size={250}
                                    />
                                </div>
                                {products.length === 0 ? (
                                    <span>
                                        Select parts to fill the keyboard
                                    </span>
                                ) : null}
                            </Col>
                        </Row>
                    ) : null // If we are not showing above, we'll show it to the right of the builder table,
                    // Showing it on the right of the builder table is done lower in code.
                }

                {allRows.map((productMetaData) => {
                    let productMetaDatas = [productMetaData];
                    if (
                        productMetaData.children &&
                        productMetaData.children.length > 0
                    ) {
                        productMetaDatas = productMetaData.children;
                    }
                    const filteredProducts = products.filter((product) => {
                        return productMetaData.path === product.type;
                    });

                    let selectButtons = null;
                    if (productMetaDatas.length > 1) {
                        selectButtons = productMetaDatas.map((c) => {
                            return (
                                <Col key={c.path} xs={4} sm={6} md={4} lg={4}>
                                    <Link
                                        style={{ textAlign: "center" }}
                                        className="accessory-link clickable margin-medium"
                                        to={`/listing/${c.path}`}
                                        onClick={() => navigateToListing()}
                                    >
                                        {c.label}
                                    </Link>
                                </Col>
                            );
                        });
                    } else {
                        selectButtons = [
                            <Col key={productMetaDatas[0].path}>
                                <Link
                                    to={`/listing/${productMetaDatas[0].path}`}
                                    onClick={() => navigateToListing()}
                                >
                                    <Button
                                        className="text-primary"
                                        variant="secondary"
                                    >
                                        <b>Browse</b>
                                    </Button>
                                </Link>
                            </Col>,
                        ];
                    }

                    let productRowContent = null;
                    if (
                        filteredProducts &&
                        filteredProducts.length &&
                        filteredProducts.length > 0
                    ) {
                        productRowContent = (
                            <Row className="align-items-center">
                                <Col sm={1}>
                                    <TypeLabel
                                        navigate={navigateToListing}
                                        type={productMetaData.path}
                                        name={productMetaData.label}
                                        showAddMore={
                                            filteredProducts.length > 0 &&
                                            /*productMetaData.showAddMore*/ true
                                        }
                                    />
                                </Col>
                                <Col>
                                    {filteredProducts.map((product, i) => {
                                        return (
                                            <React.Fragment key={product.id}>
                                                <ProductRow
                                                    hit={product}
                                                    selectProduct={null}
                                                    removeProduct={
                                                        removeProduct
                                                    }
                                                    showSelectButton
                                                    setAlert={setAlert}
                                                />
                                                {filteredProducts.length > 1 &&
                                                i <
                                                    filteredProducts.length -
                                                        1 ? (
                                                    <hr></hr>
                                                ) : null}
                                            </React.Fragment>
                                        );
                                    })}
                                    {productMetaDatas.length > 1 ? (
                                        <React.Fragment>
                                            <hr></hr>
                                            {selectButtons}
                                        </React.Fragment>
                                    ) : null}
                                </Col>
                            </Row>
                        );
                    } else {
                        productRowContent = (
                            <Row className="align-items-center">
                                <Col xs={3} sm={2} md={1}>
                                    <TypeLabel
                                        navigate={navigateToListing}
                                        type={productMetaData.path}
                                        name={productMetaData.label}
                                        showAddMore={
                                            filteredProducts.length > 0 &&
                                            /*productMetaData.showAddMore*/ true
                                        }
                                    />
                                </Col>
                                {selectButtons.length === 1 ? (
                                    <Col xs={6} sm={4} md={2}>
                                        <div className="horizontal-center select-buttons-margin-top">
                                            {selectButtons}
                                        </div>
                                    </Col>
                                ) : (
                                    <Col
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        style={{ marginTop: "16px" }}
                                    >
                                        <Row>{selectButtons}</Row>
                                    </Col>
                                )}
                            </Row>
                        );
                    }

                    return (
                        <div
                            key={`row-${productMetaData.label}`}
                            className="product_row"
                        >
                            {productRowContent}
                        </div>
                    );
                })}
            </span>
            <span
                ref={element}
                style={{ marginTop: "-30px", width: "300px", float: "right" }}
            ></span>
            {shouldShowProgressAboveProducts ? null : (
                <span
                    style={{
                        ...(isSticky && element && element.current
                            ? { position: "fixed", top: 30 }
                            : {}),
                        width: "300px",
                        float: "right",
                    }}
                >
                    <Progress
                        selectedBase={selectedBase}
                        products={products}
                        size={300}
                    />
                </span>
            )}
        </div>
    );
};

export default BuilderTable;
