import Keyboard from '../Assets/keyboard.png';
import DIY from '../Assets/diy.png';
import Custom from '../Assets/custom.png';

export const baseLocalStorageSource = 'base';
export const defaultBaseSelection = 'kit';
export const baseOptions = [
  { label: 'CUSTOM', value: 'custom', img: Custom },
  { label: 'DIY Kit', value: 'kit', img: DIY },
  { label: 'PREBUILT', value: 'prebuilt', img: Keyboard }
];

export const sizeLocalStorageSource = 'size';
export const defaultSizeSelection = 'ALL';
export const sizeApplicableTypes = ['pcb', 'case', 'plate', 'prebuilt', 'kit'];
export const sizeOptions = [
  { label: 'ALL', value: 'ALL', hideAsFacet: true },
  { label: 'Full (100%)', value: '100', badgeColor: 'danger', badgeText: '100%' },
  { label: 'TKL (80%)', value: '80', badgeColor: 'info', badgeText: '80%' },
  { label: '75%', value: '75', badgeColor: 'primary', badgeText: '75%' },
  { label: '65%', value: '65', badgeColor: 'success', badgeText: '65%' },
  { label: '60%', value: '60', badgeColor: 'warning', badgeText: '60%' },
  { label: '40%', value: '40', badgeColor: 'light', badgeText: '40%' }
];

export const hotswapLocalStorageSource = 'hotswap';
export const defaultHotswapSelection = 'ALL';
export const hotswapApplicableTypes = ['pcb', 'prebuilt', 'kit'];
export const hotswapOptions = [
  { label: 'ALL', value: 'ALL', hideAsFacet: true },
  { label: 'Hotswap Only', value: 'hotswap', badgeColor: 'dark', badgeText: 'hotswap' }
];

export const profileApplicableTypes = ['cap'];
export const profileOptions = [
  { label: 'ALL', value: 'ALL', hideAsFacet: true },
  { label: 'Cherry', value: 'Cherry' },
  { label: 'XDA', value: 'XDA' },
  { label: 'OEM', value: 'OEM' },
  { label: 'MDA', value: 'MDA' },
  { label: 'DSA', value: 'DSA' },
  { label: 'SA', value: 'SA' },
  { label: 'DSS', value: 'DSS' },
  { label: 'DCS', value: 'DCS' },
  { label: 'KAT', value: 'KAT' },
  { label: 'MT3', value: 'MT3' },
];


export const switchLocalStorageSource = 'switch';
export const defaultSwitchSelection = 'ALL';
export const switchApplicableTypes = ['switch'];
export const switchOptions = [
  { label: 'ALL', value: 'ALL', hideAsFacet: true },
  { label: 'Linear', value: 'Linear', badgeColor: 'info', badgeText: 'linear' },
  { label: 'Tactile', value: 'Tactile', badgeColor: 'dark', badgeText: 'tactile' },
  { label: 'Clicky', value: 'Clicky', badgeColor: 'light', badgeText: 'clicky' }
];

const localStorage = window.localStorage;

export const getUserSelectedSize = (products) => {
  if (!products) { products = [] }
  let sizeCompatibility = null;
  // Set the size compatibility to be the local storage stored size from the intro flow.
  const storedSize = localStorage.getItem(sizeLocalStorageSource);
  if (storedSize && storedSize !== 'ALL') {
    sizeCompatibility = storedSize;
  }
  const sizeProducts = products && products.filter ? products.filter((p) => {
    return p.type === 'prebuilt' || p.type === 'kit' || p.type === 'case' || p.type === 'pcb' || p.type === 'plate'
  }) : [];

  // If we have a product selected with a size already,
  let selectedSize = null;
  for (const i in sizeProducts) {
    const p = sizeProducts[i];
    if (!selectedSize && p.sizeCompatibility) {
      selectedSize = p.sizeCompatibility;
    }
    else if (selectedSize && p.sizeCompatibility !== selectedSize) {
      selectedSize = null;
      sizeCompatibility = null;
      break;
    }
  }
  if (selectedSize) {
    sizeCompatibility = selectedSize;
  }
  return {
    sizeCompatibility: sizeCompatibility,
    clearSizeCompatibility: !selectedSize ? (() => { localStorage.setItem(sizeLocalStorageSource, '') }) : null
  };
}

export const getUserSelectedPcb = () => {
  const hotswapPreference = localStorage.getItem(hotswapLocalStorageSource);
  return hotswapPreference && hotswapPreference === 'hotswap' ? 'hotswap' : null;
}

// If the user has a keycap that has some sort of compatibility - return that selected compatibility.
// If the user has multiple different types of keycaps selected, return null
// If the user has no keycaps selected, return null
export const getUserSelectedProfile = (products) => {
  if (!products) { products = [] }
  let profile = null;
  const keycaps = products && products.filter ? products.filter((p) => {
    return p.type === 'cap'
  }) : []
  for (const i in keycaps) {
    const p = keycaps[i];
    if (!profile && p.profileCompatibility) {
      profile = p.profileCompatibility;
    }
    else if (profile && profile !== p.profileCompatibility) {
      profile = null;
      break;
    }
  }
  return profile;
}

// If the user has a switch that has some sort of compatibility - return that selected compatibility.
// If the user has multiple different types of switches selected, return null
// If the user has no switches selected, return null
export const getUserSelectedSwitch = (products) => {
  if (!products) { products = [] }
  let switchCompatibility = null;
  // Set the switch to be the local storage stored switch from the intro flow.
  const storedSwitch = localStorage.getItem(switchLocalStorageSource);
  if (storedSwitch && storedSwitch !== 'ALL') {
    switchCompatibility = storedSwitch;
  }
  const curSwitches = products && products.filter ? products.filter((p) => {
    return p.type === 'switch'
  }) : []
  for (const i in curSwitches) {
    const p = curSwitches[i];
    if (!switchCompatibility && p.switchCompatibility) {
      switchCompatibility = p.switchCompatibility;
    }
    else if (switchCompatibility && switchCompatibility !== p.switchCompatibility) {
      switchCompatibility = null;
      break;
    }
  }
  return switchCompatibility;
}
