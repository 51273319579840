import React, { useState, useEffect } from "react";
import { Row, Spinner, Card, Button, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
    sizeLocalStorageSource,
    baseLocalStorageSource,
    switchLocalStorageSource,
    hotswapLocalStorageSource,
} from "../API/user-preferences";
import Container from "react-bootstrap/Container";
import {
    ArrowLeftCircle,
    CheckCircleFill,
    XCircleFill,
} from "react-bootstrap-icons";

import ScrollToTopOnMount from "../Components/scroll-to-top-mount";

import "../Styles/keyboard-builder.css";
import { Helmet } from "react-helmet";

import Keyboard from "../Assets/keyboard.png";
import DIY from "../Assets/diy.png";
import Custom from "../Assets/custom.png";

import Size40 from "../Assets/size-40.png";
import Size60 from "../Assets/size-60.png";
import Size65 from "../Assets/size-65.png";
import Size75 from "../Assets/size-75.png";
import Size80 from "../Assets/size-80.png";
import Size100 from "../Assets/size-100.png";
import QuestionMark from "../Assets/question-mark.png";

import Linear from "../Assets/linear.png";
import Tactile from "../Assets/tactile.png";
import Clicky from "../Assets/clicky.png";

import Solder from "../Assets/solder.png";
import NoSolder from "../Assets/no-solder.png";

const localStorage = window.localStorage;

const ContainsIndicator = ({ truthy }) => {
    return truthy ? (
        <span className="green">
            <CheckCircleFill size={1} />
        </span>
    ) : (
        <span style={{ color: "rgb(171 76 76)" }}>
            <XCircleFill size={1} />
        </span>
    );
};

const sizeDetails = (hasArrowKeys, hasFKeys, hasNumpad) => {
    return (
        <div style={{ fontSize: "15px" }}>
            <Row>
                <Col xs={12}>
                    <span className="m-1">
                        <ContainsIndicator truthy={hasArrowKeys} />
                        <span> Arrow Keys</span>
                    </span>
                </Col>
                <Col xs={12}>
                    <span className="m-1">
                        <ContainsIndicator truthy={hasFKeys} />
                        <span> F Keys</span>
                    </span>
                </Col>
                <Col xs={12}>
                    <span className="m-1">
                        <ContainsIndicator truthy={hasNumpad} />
                        <span> Numpad</span>
                    </span>
                </Col>
            </Row>
        </div>
    );
};

const switchDetails = (type) => {
    if (type === "linear") {
        return (
            <div style={{ fontSize: "15px" }}>
                <Row>
                    <Col xs={12}>
                        <span className="m-1">
                            <ContainsIndicator truthy={true} />
                            <span> Smooth</span>
                        </span>
                    </Col>
                    <Col xs={12}>
                        <span className="m-1">
                            <ContainsIndicator truthy={true} />
                            <span> Silent</span>
                        </span>
                    </Col>
                </Row>
            </div>
        );
    } else if (type === "tactile") {
        return (
            <div style={{ fontSize: "15px" }}>
                <Row>
                    <Col xs={12}>
                        <span className="m-1">
                            <ContainsIndicator truthy={true} />
                            <span> Bumpy</span>
                        </span>
                    </Col>
                    <Col xs={12}>
                        <span className="m-1">
                            <ContainsIndicator truthy={true} />
                            <span> Moderate Noise</span>
                        </span>
                    </Col>
                </Row>
            </div>
        );
    } else if (type === "clicky") {
        return (
            <div style={{ fontSize: "15px" }}>
                <Row>
                    <Col xs={12}>
                        <span className="m-1">
                            <ContainsIndicator truthy={true} />
                            <span> Bumpy</span>
                        </span>
                    </Col>
                    <Col xs={12}>
                        <span className="m-1">
                            <ContainsIndicator truthy={true} />
                            <span> Loud Noise</span>
                        </span>
                    </Col>
                </Row>
            </div>
        );
    } else {
        return null;
    }
};

const FirstBuildPage = ({ history }) => {
    const [result, setResult] = useState({});
    const [selectedPage, setSelectedPage] = useState(0);

    useEffect(() => {
        const scrollUpButton = document.querySelector(
            ".ScrollUpButton__Container "
        );
        if (scrollUpButton) {
            scrollUpButton.click();
        }
    }, [selectedPage]);

    const goToBuildPage = (e) => {
        const buildLink = sessionStorage.getItem("buildLink");
        if (buildLink) {
            history.push(`/build/${buildLink}`, {
                showNextSteps:
                    buildLink === "hotswap-essentials" ||
                    buildLink === "all-essentials",
            });
        } else {
            history.push("/");
        }
        if (e) {
            e.preventDefault();
        }
    };

    const reassurancePrompt = (
        <div
            style={{ fontWeight: "500", marginTop: "4px" }}
            className="small-font-size"
        >
            All selections may be changed later
        </div>
    );

    const questions = [
        {
            prompt: (
                <div>
                    We will help get you started in building a customized
                    keyboard, including the tools and accessories you&apos;ll
                    need to reach your endgame.
                    <br />
                    To start, choose a <b>base</b> for your keyboard
                    <br />
                    {reassurancePrompt}
                </div>
            ),
            shouldShow: () => {
                return true;
            },
            answers: [
                {
                    answer: "Custom",
                    objAnswer: "custom",
                    img: Custom,
                    details:
                        "Select your case, PCB, and plate separately. This is the most customizable option, but may be more difficult for a first build.",
                },
                {
                    answer: "DIY Kit (Recommended)",
                    objAnswer: "kit",
                    img: DIY,
                    details: (
                        <div>
                            Select a kit in the size and color you like, and
                            receive a case, PCB, and plate as part of the kit.
                            Get the full custom build experience without the
                            hassle of picking every part.
                        </div>
                    ),
                },
                {
                    answer: "Prebuilt",
                    objAnswer: "prebuilt",
                    img: Keyboard,
                    details:
                        "Select a prebuilt fully functional keyboard. KeyboardPartPicker will only suggests modular keyboards, so that you may make custom modifications to the switches and keycaps on your keyboard.",
                },
            ],
            resultKey: "base",
        },
        {
            prompt: (
                <div>
                    Next, choose a <b>form-factor</b> (size) for your keyboard
                    <br />
                    {reassurancePrompt}
                </div>
            ),
            shouldShow: () => {
                return true;
            },
            answers: [
                {
                    answer: "Full (100%)",
                    objAnswer: "100",
                    img: Size100,
                    details: sizeDetails(true, true, true),
                },
                {
                    answer: "Tenkeyless (a.k.a TKL or 80%)",
                    objAnswer: "80",
                    img: Size80,
                    details: sizeDetails(true, true, false),
                },
                {
                    answer: "75%",
                    objAnswer: "75",
                    img: Size75,
                    details: sizeDetails(true, true, false),
                },
                {
                    answer: "65%",
                    objAnswer: "65",
                    img: Size65,
                    details: sizeDetails(true, false, false),
                },
                {
                    answer: "60%",
                    objAnswer: "60",
                    img: Size60,
                    details: sizeDetails(false, false, false),
                },
                {
                    answer: "40%",
                    objAnswer: "40",
                    img: Size40,
                    details: sizeDetails(false, false, false),
                },
                {
                    answer: "I don't know yet",
                    objAnswer: "ALL",
                    img: QuestionMark,
                },
            ],
            resultKey: "size",
        },
        {
            prompt: (
                <div>
                    Next, choose a <b>switch type</b> for your keyboard
                    <br />
                    {reassurancePrompt}
                </div>
            ),
            shouldShow: () => {
                return true;
            },
            answers: [
                {
                    answer: "Linear",
                    objAnswer: "Linear",
                    img: Linear,
                    details: switchDetails("linear"),
                },
                {
                    answer: "Tactile",
                    objAnswer: "Tactile",
                    img: Tactile,
                    details: switchDetails("tactile"),
                },
                {
                    answer: "Clicky",
                    objAnswer: "Clicky",
                    img: Clicky,
                    details: switchDetails("clicky"),
                },
                {
                    answer: "I don't know yet",
                    objAnswer: "ALL",
                    img: QuestionMark,
                },
            ],
            resultKey: "switch",
        },
        {
            prompt: (
                <div>
                    When making a custom keyboard, you may use a soldering iron
                    and solder to attach or replace switches to your PCB. An
                    alternative to this is to use a hot swappable PCB which does
                    not require soldering, and instead allows you to click
                    switches into your PCB. Hot swap boards are normally less
                    customizable, but make for easy swapping of switches on your
                    board.
                    <br />
                    <b>Would you like to use solder as part of your build?</b>
                    <br />
                    {reassurancePrompt}
                </div>
            ),
            shouldShow: () => {
                return true;
            }, //return curAnswers.base === 'custom' || curAnswers.base === 'kit' },
            answers: [
                {
                    answer: "No, hot swap PCBs only",
                    objAnswer: "hotswap",
                    img: NoSolder,
                },
                {
                    answer: "Yes, lets get cooking",
                    objAnswer: "ALL",
                    img: Solder,
                },
            ],
            resultKey: "hotswap",
        },
        {
            prompt: (
                <div>
                    <Spinner />
                </div>
            ),
            shouldShow: () => {
                localStorage.setItem(sizeLocalStorageSource, result.size);
                localStorage.setItem(baseLocalStorageSource, result.base);
                localStorage.setItem(switchLocalStorageSource, result.switch);
                localStorage.setItem(hotswapLocalStorageSource, result.hotswap);
                const buildPath =
                    result.hotswap === "hotswap"
                        ? "hotswap-essentials"
                        : "all-essentials";
                history.push(`/build/${buildPath}`, {
                    showNextSteps: true,
                });
                return true;
            },
            answers: [],
            showResult: true,
        },
    ];

    const curPage = questions[selectedPage];

    if (!curPage.shouldShow(result)) {
        setSelectedPage(selectedPage + 1);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>First Time Build | Keyboard Part Picker</title>
                <meta
                    name="description"
                    content="Looking to build your first mechanical keyboard? Our
                    workflow guide will walk you through the process of picking
                    the perfect parts for your build. From switches to keycaps,
                    we've got you covered. Start building now!"
                />
                <meta
                    property="og:title"
                    content="First Time Build | Keyboard Part Picker"
                />
                <meta
                    property="og:description"
                    content="Looking to build your first mechanical keyboard? Our
                    workflow guide will walk you through the process of picking
                    the perfect parts for your build. From switches to keycaps,
                    we've got you covered. Start building now!"
                />
                <meta
                    name="twitter:title"
                    content="First Time Build | Keyboard Part Picker"
                />
                <meta
                    name="twitter:description"
                    content="Looking to build your first mechanical keyboard? Our
                    workflow guide will walk you through the process of picking
                    the perfect parts for your build. From switches to keycaps,
                    we've got you covered. Start building now!"
                />
            </Helmet>
            <Container fluid className="first-build-container">
                <ScrollToTopOnMount />
                <div style={{ marginTop: "30px" }}> </div>
                <div className="padding-medium horizontal-center">
                    {
                        <a
                            className="clickable text-dark"
                            onClick={() => {
                                if (selectedPage > 0) {
                                    setSelectedPage(selectedPage - 1);
                                } else {
                                    goToBuildPage();
                                }
                            }}
                        >
                            <ArrowLeftCircle
                                style={{ height: "26px", width: "26px" }}
                            />
                        </a>
                    }
                    <div
                        className="medium-font-size "
                        style={{ marginTop: "30px" }}
                    >
                        {curPage.prompt}
                    </div>
                </div>{" "}
                <Row>
                    {" "}
                    {curPage.answers.map((answer) => {
                        return (
                            <div
                                key={answer.objAnswer}
                                className="col-md-3 col-s-4 col-xs-6 py-2 align-items-center"
                            >
                                <Card
                                    key={answer.answer}
                                    className="pb-0"
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {answer.img ? (
                                            <Card.Img
                                                style={{
                                                    height: "140px",
                                                    width: "auto",
                                                }}
                                                className="p-3"
                                                variant="top"
                                                src={answer.img}
                                            />
                                        ) : null}
                                    </div>
                                    <Card.Body className="d-flex flex-column bg-primary text-light">
                                        <Card.Title className="mb-0">
                                            <h4>{answer.answer}</h4>
                                        </Card.Title>
                                        <Card.Text>{answer.details}</Card.Text>
                                        <Button
                                            className="mt-auto"
                                            variant="secondary"
                                            onClick={() => {
                                                const newResult = {
                                                    ...result,
                                                    [curPage.resultKey]:
                                                        answer.objAnswer,
                                                };
                                                setResult(newResult);
                                                setSelectedPage(
                                                    selectedPage + 1
                                                );
                                            }}
                                        >
                                            Select
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </div>
                        );
                    })}{" "}
                </Row>{" "}
            </Container>
        </React.Fragment>
    );
};

export default withRouter(FirstBuildPage);
