import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getCountryCode } from '../API/countries';
import Icon from '@mdi/react'
import { mdiEarth } from '@mdi/js';
import ReactCountryFlag from "react-country-flag"

const CountryIcon = ({ location }) => {
  const countryInfo = getCountryCode(location);
  let icon = null;
  if (countryInfo.countryCode === 'global') {
    icon = (
      <span className="">
        <Icon path={mdiEarth} size={.7} style={{marginTop: '-1px'}} />
      </span>
    );
  }
  else {
    icon = (
      <span className="pl-1">
        <ReactCountryFlag countryCode={countryInfo.countryCode} svg  style={{marginTop:'-1px'}} />
      </span>
    )
  }

  return (
    <OverlayTrigger placement="top"
      delay={{ show: 100, hide: 500 }}
      overlay={
        <Tooltip>
          {countryInfo.hoverText}
        </Tooltip>
      }
    >
      {icon}
    </OverlayTrigger>
  )
}

export default CountryIcon
