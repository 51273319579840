import React from 'react';
import { Image } from 'react-bootstrap';
import CapImg from '../Assets/3D-Keycaps.png';
import CapImgBW from '../Assets/3D-Keycaps-BW.png';
import SwitchImg from '../Assets/3D-Switches.png';
import SwitchImgBW from '../Assets/3D-Switches-BW.png';
import PlateImg from '../Assets/3D-Plate.png';
import PlateImgBW from '../Assets/3D-Plate-BW.png';
import PcbImg from '../Assets/3D-PCB.png';
import PcbImgBW from '../Assets/3D-PCB-BW.png';
import StabilizerImg from '../Assets/3D-Stabilizer.png';
import StabilizerImgBW from '../Assets/3D-Stabilizer-BW.png';
import CaseImg from '../Assets/3D-Case.png';
import CaseImgBW from '../Assets/3D-Case-BW.png';

export const partTypes = [
  { type: 'cap', img: CapImg, bwImg: CapImgBW },
  { type: 'switch', img: SwitchImg, bwImg: SwitchImgBW },
  { type: 'plate', img: PlateImg, bwImg: PlateImgBW },
  { type: 'pcb', img: PcbImg, bwImg: PcbImgBW },
  { type: 'stabilizer', img: StabilizerImg, bwImg: StabilizerImgBW },
  { type: 'case', img: CaseImg, bwImg: CaseImgBW }
];

export const StackedKeyboard = ({ parts, size }) => {
  return (
    <div style={{ height: size }}>
      {
        parts.map((part, i) => {
          return (
            <div key={part.type} style={{ position: 'absolute', width: size, height: size, zIndex: 10-i }}>
              <Image style={part.selected ? {opacity:1} : {opacity: 0.55} } className="img-fluid" src={part.selected ? part.img : part.bwImg} />
            </div>
          )
        })
      }
    </div>
  );
}

// selectedBase is also passed in, but not used right now
const Progress = ({ products, size }) => {

  const parts = partTypes.map((partType) => {
    const type = partType.type;
    const productTypeIsSelected = products.some((p) => {
      if (p.type === 'prebuilt') { return true; }
      else if (p.type === 'kit') { return type === 'case' || type === 'plate' || type === 'pcb' }
      else { return type === p.type }
    });
    return { ...partType, selected: productTypeIsSelected }
  });

  return (
    <div style={{width: '100%'}}>
      <StackedKeyboard parts={parts} size={size} />
    </div>
  );
}

export default Progress;
