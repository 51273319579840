import React from 'react';
import { withRouter } from 'react-router-dom';

import { Col, Row, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found | Keyboard Part Picker</title>
      </Helmet>
      <Container fluid className="main">
        <Row className="main_header">
          <Col sm={4}></Col>
          <Col sm={4}>
            <h1 className="main_text">Page Not Found :(</h1>
          </Col>
          <Col sm={4}>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default withRouter(NotFound);
