import React from "react";

import { Row, Col, Button } from "react-bootstrap";
import { connectInfiniteHits } from "react-instantsearch-dom";
import ProductPlaceholder from "./product-placeholder";
import ProductRowInsights from "./product-row-insights";

function buildHits(hits, loading, history) {
    let components = [];
    hits.map((hit) => {
        return components.push(
            <ProductRowInsights key={hit.id} hit={hit} history={history} />
        );
    });
    if (loading) {
        for (let i = 0; i < 25; i++) {
            components.push(
                <Row key={i} className="align-items-center hit_item">
                    <Col xs={12}>
                        <ProductPlaceholder />
                    </Col>
                </Row>
            );
        }
    }
    return components;
}

const Hits = ({ hits, history, refineNext, hasMore, loading, setLoading }) => {
    let components = buildHits(hits, loading, history);
    if (components.length === 0) {
        components.push(
            <Row key="empty-results">
                <Col xs={12}>
                    <h4>
                        No results found
                        <ul>
                            <li>Try modifying your search filters</li>
                            <li>Double-check your spelling</li>
                            <li>Use fewer keywords</li>
                            <li>Search for a less specific item</li>
                        </ul>
                    </h4>
                </Col>
            </Row>
        );
        return components;
    } else {
        components.push(
            <div key={`load_more`} className="horizontal-center">
                <Button
                    style={{ width: "200px" }}
                    key="show-more-button"
                    onClick={() => {
                        setLoading(true);
                        refineNext();
                    }}
                    disabled={!hasMore}
                    className="load_more_btn"
                >
                    {hasMore ? `Load more` : `No more results`}
                </Button>
            </div>
        );

        return components;
    }
};

const ProductHits = connectInfiniteHits(Hits);

export default ProductHits;
