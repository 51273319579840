import React from 'react';
import { withRouter } from 'react-router-dom';

import ScrollToTopOnMount from '../Components/scroll-to-top-mount';

import { Col, Row, Container } from 'react-bootstrap';
import Icon from '@mdi/react'
import { mdiFlagVariantOutline } from '@mdi/js'
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const flag = (
    <Icon path={mdiFlagVariantOutline}
      size={1}
      color="red"
    />);
  return (
    <>
      <Helmet>
        <title>Contact Us | Keyboard Part Picker</title>
      </Helmet>
      <Container fluid className="main">
        <ScrollToTopOnMount />
        <Row className="main_header">
          <Col sm={4}></Col>
          <Col sm={4}>
            <h1 className="main_text">Contact Us</h1>
          </Col>
          <Col sm={4}>
          </Col>
        </Row>
        <Row>
          <Col lg={3}></Col>
          <Col>
            <p>We created KeyboardPartPicker in the hopes of making the custom keyboard building hobby more accessible.</p>
            <p>As such, we are always looking for feedback that may help us improve the site or the experience.</p>
            <p>Feel free to email us at <u><a href="mailto:hello@keyboardpartpicker.com">hello@keyboardpartpicker.com</a></u></p>
            <p>Specifically, we would love feedback on
            <ul>
                <li>Products shown on the site
                <ul>
                    <li>You can also send us feedback directly from a product by clicking the {flag} icon</li>
                  </ul>
                </li>
                <li>Established vendors you would like seen on the site</li>
                <li>Bugs or limitations with the site</li>
              </ul>
            </p>
          </Col>
          <Col lg={3}></Col>
        </Row>
      </Container>
    </>
  )
}

export default withRouter(ContactUs);
