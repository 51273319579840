import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';
import { lightTheme } from './Styles/theme';

// Some component libraries we use trigger warnings because of the strict mode,
// so disabling strict mode for now, as it makes it hard to develop and see the console/real errors.

ReactDOM.render(
  <React.Fragment>
    <Helmet>
      <title>Keyboard Part Picker</title>
      <meta charset="utf-8" />
      <meta name="description" content="Build a custom keyboard. Share your build. Browse keyboard parts from many vendors." />
      <meta name="keywords" content="keyboard,keyboard parts,custom keyboard,mechanical keyboard,keeb,case,plate,pcb,caps,keycaps,switches,switch,deskmat,deskpad,cable,kb,mk,custom,search,diy" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="theme-color" content={lightTheme.colors.primary} />

      <meta property="og:title" content="Keyboard Part Picker" />
      <meta property="og:description" content="Build a custom keyboard. Share your build. Browse keyboard parts from many vendors." />
      <meta property="og:image" content="/logo512.png" />
      <meta name="twitter:title" content="Keyboard Part Picker " />
      <meta name="twitter:description" content="Build a custom keyboard. Share your build. Browse keyboard parts from many vendors." />
      <meta name="twitter:image" content="/logo512.png" />

      <link rel="canonical" href="https://keyboardpartpicker.com/" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link rel="preload" as="style" href="https://fonts.googleapis.com/css2?family=Exo:wght@700&display=swap" />
      <link rel="preload" as="style" href="https://fonts.googleapis.com/css2?family=Nunito:wght@600;700&display=swap" />
      <link rel="stylesheet" media="print" onLoad="this.onload=null;this.removeAttribute('media');" href="https://fonts.googleapis.com/css2?family=Exo:wght@700&display=swap" />
      <link rel="stylesheet" media="print" onLoad="this.onload=null;this.removeAttribute('media');" href="https://fonts.googleapis.com/css2?family=Nunito:wght@600;700&display=swap" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/logo192.png" />
      <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
    </Helmet>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
