import React from "react";

import "nouislider/dist/nouislider.min.css";
import { connectRange } from "react-instantsearch-dom";
import { Row, Col, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import noUiSlider from "nouislider";

const RefinementToggle = ({
    currentRefinement,
    min,
    max,
    refine,
    title,
    visible,
}) => {
    const [sliderInitialValues, setSliderInitialValues] = useState({
        min: null,
        max: null,
    });
    const [sliderValues, setSliderValues] = useState({ min: null, max: null });

    const [slider, setSlider] = useState(null);

    useEffect(() => {
        if (
            min >= 0 &&
            max >= 0 &&
            (sliderInitialValues.min !== min || sliderInitialValues.max !== max)
        ) {
            setSliderInitialValues({ min: min, max: max });
            setSliderValues({ min: min, max: max });
            const snapSlider = document.getElementById("slider_element");
            const sliderOptions = {
                start: [min, max],
                connect: true,
                step: 1,
                range: {
                    min: [min],
                    max: [max],
                },
            };
            if (!slider) {
                noUiSlider.create(snapSlider, sliderOptions);
                setSlider(snapSlider);
            } else {
                setTimeout(() => {
                    slider.noUiSlider.updateOptions(sliderOptions, true);
                }, 0);
            }
        }
    }, [currentRefinement]);

    useEffect(() => {
        if (slider) {
            slider.noUiSlider.on("slide", (val) => {
                setSliderValues({ min: val[0], max: val[1] });
            });
            slider.noUiSlider.on("set", (val) => {
                try {
                    refine({ min: val[0], max: val[1] });
                } catch (e) {
                    console.error("Failed to refine, continuing anyways..", e);
                }
            });
        }
    }, [slider]);

    if (!visible) {
        return null;
    }
    // return the DOM output
    return (
        <Card className="refinement_card">
            <div className="refinement_header">{title}</div>
            <Row>
                <Col>
                    <div id="slider_container">
                        <div id="slider_element"></div>
                    </div>
                    <div className="slider_values">
                        ${sliderValues.min} to ${sliderValues.max}
                    </div>
                </Col>
            </Row>
        </Card>
    );
};

const ProductRefinementSlider = connectRange(RefinementToggle);

export default ProductRefinementSlider;
