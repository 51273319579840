const common = {
    colors: {
        green: '#00A676',
        red: '#D62828',
        lightGreen: 'rgba(0, 255, 0, 0.25)',
        lightRed: 'rgba(255, 0, 0, 0.25)',
        lightOrange: 'rgba(255, 165, 0, 0.25)',
    }
}

// https://coolors.co/075c7a-032936-e3b23c-7de2d1-c62e65
// original primary: 386394
// possible alternative primaries: CA895F, 119DA4, 8963BA, 8789C0, 4F9D69, 387a94
// original fg: 454545
export const lightTheme = {
    colors: {
        primary: '#075c7a',
        lighterPrimary: '#7DE2D1',
        background: '#FFFFFF',
        backgroundLine: '#d1d1d1',
        nestedBackground: '#FFFFFF',
        foreground: '#011C27',
        textColor: '#f8f8ff',
        linkColor: '#000000',
        aisBorderColor: '#000000',
        aisInnerColor: '#000000',
        alertColor: '#708090',
        dangerColor: '#660000',
        darkPrimary: '#044055',
        darkerPrimary: '#032936',
        ...common.colors
    },
}

export const darkTheme = {
    colors: {
        primary: '#387a94',
        lighterPrimary: '#7eb7ce',
        background: '#303031',
        backgroundLine: '#d1d1d1',
        nestedBackground: '#dbdbe0',
        foreground: '#808d93',
        textColor: '#f8f8ff',
        linkColor: '#FFFFFF',
        aisBorderColor: '#808080',
        aisInnerColor: '#FFFFFF',
        alertColor: '#214958',
        dangerColor: '#6a5c6f',
        darkPrimary: '#044055',
        darkerPrimary: '#032936',
        ...common.colors
    },
}
