import React from 'react'
import { Row, Col } from 'react-bootstrap';
import Compatibility from './compatibility';

const BuilderInfoBox = ({ price, products, loading, showingFullPrice }) => {
  return (
    <div className="pb-2">
      <Row style={{ minHeight: '36px' }}>
        <Col md={6}>
          <Compatibility products={products} loading={loading} />
        </Col>
        <Col md={6}>
          <span className="price horizontal-right vertical-bottom md-center mt-auto">Total Cost: <strong><span>${(Math.round(parseFloat(price) * 100) / 100).toFixed(2)}</span></strong></span>
          { !showingFullPrice ? <span className="horizontal-right md-center small-font-size">Total cost does not include Amazon products</span> : null }
        </Col>
      </Row>
    </div>
  )
}

export default BuilderInfoBox
