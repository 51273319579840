const localStorage = window.localStorage;
const historyKey = 'history';

export const addToHistory = ({ action, resource, linkId }) => {
  if (!linkId || linkId === '' || linkId === '/') {
    return;
  }
  let history = [];
  try {
    history = JSON.parse(localStorage.getItem(historyKey));
    if (!history) {
      history = [];
    }
  }
  catch (e) {
    console.error('Failed to parse existing history', e);
  }
  // Action could be 'add', 'remove', 'visit', 'none'
  if (history.length > 0 && linkId === history[history.length - 1].linkId) {
    return;
  }
  history.push({ action: action, resource: resource, linkId: linkId, when: new Date().getTime() });
  if (history.length > 100) {
    history.shift();
  }
  localStorage.setItem(historyKey, JSON.stringify(history));
}

export const getHistory = () => {
  let history = [];
  try {
    history = JSON.parse(localStorage.getItem(historyKey));
    if (!history) {
      history = [];
    }
  }
  catch (e) {
    console.error('Reached error getting history', e);
  }
  return history;
}

export const clearHistory = () => {
  localStorage.removeItem(historyKey);
}
