import React from 'react';
import { FormControl } from 'react-bootstrap';

const col = [
  'name',
  'id',
  'type',
  'compatibility',
  'link',
]

const InputProductRow = ({ productInformationProps, setProductInformationProps }) => {


  return (
    <React.Fragment>
        {col.map( (el, i) => {
        if (el === 'id') {
          return <td key={productInformationProps[el] || 'temp1'}>{productInformationProps[el]}</td>
        }
          return (<td key={i}><FormControl placeholder={el} value={productInformationProps[el]} onChange={(ev) => setProductInformationProps({
            ...productInformationProps,
            [el]: ev.target.value
          })} /></td>)
        })}
    </React.Fragment>
  )
}

export default InputProductRow;
