import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import ButtonGroupFilter from "./button-group-filter";
import ConfirmButton from "./confirm-button";
import { submitReport } from "../API/product";

const reasons = [
    { value: "bad_type", label: "Wrong Section" },
    { value: "bad_data", label: "Inaccurate Data" },
    { value: "bad_retailer", label: "Untrustworthy Retailer" },
    { value: "other", label: "Other" },
];

const initialSelect = "other";

const ReportModal = ({ showReportModal, setShowReportModal, product }) => {
    const [selectedKey, setSelectedKey] = useState(initialSelect);
    const [optionalInfo, setOptionalInfo] = useState("");

    const handleReportClick = async () => {
        await submitReport(product, selectedKey, optionalInfo);
        setShowReportModal(false);
    };

    return (
        <Modal
            show={showReportModal}
            dialogClassName="modal-custom"
            onHide={() => {}}
        >
            <Modal.Header
                closeButton
                onClick={() => {
                    setShowReportModal(false);
                }}
            >
                <Modal.Title>Report Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="padding-medium">
                    <p>
                        You are reporting a problem with this product to
                        KeyboardPartPicker.com, NOT to the seller of this
                        product.
                    </p>
                    <p>
                        Your report will be used to improve
                        KeyboardPartPicker.com&apos;s product listings.
                    </p>
                </div>
                <div className="padding-medium">
                    <Form.Label>
                        <b>Reason for Report</b>
                    </Form.Label>
                    <ButtonGroupFilter
                        fontSize="11px"
                        selectedColor={"primary"}
                        className="padding-medium"
                        options={reasons}
                        defaultSelection={initialSelect}
                        label={`report-${product.id}`}
                        selectedCallback={(name) => setSelectedKey(name)}
                    />
                </div>
                <Form.Group
                    controlId={`report-${product.id}.ReportTextArea`}
                    className="padding-medium"
                >
                    <Form.Control
                        placeholder="More Information (Optional)"
                        as="textarea"
                        rows={2}
                        value={optionalInfo}
                        onChange={(e) => {
                            setOptionalInfo(e.target.value);
                        }}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => setShowReportModal(false)}
                >
                    Cancel
                </Button>
                <ConfirmButton
                    variant="primary"
                    confirmtext="Submitting.."
                    onClick={() => {
                        handleReportClick();
                    }}
                >
                    Submit Report
                </ConfirmButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ReportModal;
