import React from 'react';
import { withRouter } from 'react-router-dom'

import { Col, Row, Container } from 'react-bootstrap';

import ScrollToTopOnMount from '../Components/scroll-to-top-mount';

import __disclaimer_html from '../Content/disclaimer.html';
import __product_accuracy_disclosure_html from '../Content/product-accuracy-disclosure.html';
import __affiliate_disclosure_html from '../Content/affiliate-disclosure.html';
import { Helmet } from 'react-helmet';

//import __affiliate_disclosure_html from './disclaimer.html';
const affiliateDisclosureTemplate = { __html: __affiliate_disclosure_html };
const disclaimerTemplate = { __html: __disclaimer_html };
const productAccuracyDisclosureTemplate = { __html: __product_accuracy_disclosure_html };

const Disclaimer = () => {
  return (
    <>
      <Helmet>
        <title>Disclaimers | Keyboard Part Picker</title>
      </Helmet>
      <Container fluid className="main">
        <ScrollToTopOnMount />
        <Row className="main_header">
          <Col sm={4}></Col>
          <Col sm={4}>
            <h1 className="main_text">Disclosures & Disclaimers</h1>
          </Col>
          <Col sm={4}>
          </Col>
        </Row>
        <Row>
          <Col lg={1}></Col>
          <Col>
            <span dangerouslySetInnerHTML={productAccuracyDisclosureTemplate} />
            <span dangerouslySetInnerHTML={affiliateDisclosureTemplate} />
            <span dangerouslySetInnerHTML={disclaimerTemplate} />
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </>
  )
}

export default withRouter(Disclaimer);
