import { autocomplete } from "@algolia/autocomplete-js";
import { flatten } from "lodash";
import { pipe } from "ramda";
import React, { createElement, Fragment, useEffect, useRef } from "react";
import { render } from "react-dom";

function debouncePromise(fn, time) {
    let timerId = undefined;

    return function debounced(...args) {
        if (timerId) {
            clearTimeout(timerId);
        }

        return new Promise((resolve) => {
            timerId = setTimeout(() => resolve(fn(...args)), time);
        });
    };
}

// Utils for plugins
function normalizeReshapeSources(sources) {
    return flatten(sources).filter(Boolean);
}

const uniqBy = (predicate) => {
    return function runUniqBy(...rawSources) {
        const sources = normalizeReshapeSources(rawSources);
        const seen = new Set();

        return sources.map((source) => {
            const items = source.getItems().filter((item) => {
                const appliedItem = predicate({ source, item });
                const hasSeen = seen.has(appliedItem);

                seen.add(appliedItem);

                return !hasSeen;
            });

            return {
                ...source,
                getItems() {
                    return items;
                },
            };
        });
    };
};

const removeDuplicates = uniqBy(({ source, item }) => {
    if (source.sourceId === "categoriesPlugin") {
        return item.type;
    } else if (source.sourceId === "recentSearchesPlugin") {
        return item.label;
    } else if (source.sourceId === "querySuggestionsPlugin") {
        return item.__autocomplete_qsCategory
            ? `${item.query}-${item.__autocomplete_qsCategory}`
            : item.query;
    } else {
        return item;
    }
});

function cx(...classNames) {
    return classNames.filter(Boolean).join(" ");
}

function hasSourceActiveItem(sourceId, state) {
    return Boolean(
        state.collections.find(
            (collection) =>
                collection.source.sourceId === sourceId &&
                collection.items.find(
                    (item) => item.__autocomplete_id === state.activeItemId
                )
        )
    );
}

export const createFillWith = ({ mainSourceId, limit }) => {
    return function runUniqBy(...rawSources) {
        const originalSources = normalizeReshapeSources(rawSources);
        const otherSources = originalSources.filter(
            (s) => s.sourceId !== mainSourceId
        );

        // Compute the total number of items per source.
        let totalItemNb = 0;
        otherSources.forEach((source) => {
            totalItemNb += source.getItems().length;
        });

        return originalSources.map((source) => {
            let transformedSource = source;

            // Limit the main source items length based on the provided limit and
            // the computed total number of items.
            if (source.sourceId === mainSourceId) {
                transformedSource = {
                    ...source,
                    getItems() {
                        return source
                            .getItems()
                            .slice(0, Math.max(limit - totalItemNb, 0));
                    },
                };
            }

            return transformedSource;
        });
    };
};

function isDetached() {
    return window.matchMedia(
        getComputedStyle(document.documentElement).getPropertyValue(
            "--aa-detached-media-query"
        )
    ).matches;
}

const fillWith = createFillWith({
    mainSourceId: "querySuggestionsPlugin",
    limit: isDetached() ? 6 : 10,
});

const combine = pipe(removeDuplicates, fillWith);

export function Autocomplete(props) {
    const containerRef = useRef(null);
    const { plugins, ...propsToAdd } = props;
    const pluginsToAdd = plugins.filter(
        (plugin) => plugin.name === "aa.localStorageRecentSearchesPlugin"
    );
    const pluginsToConvert = plugins.filter(
        (plugin) => plugin.name !== "aa.localStorageRecentSearchesPlugin"
    );
    const debounced = debouncePromise((items) => Promise.resolve(items), 300);
    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }

        const search = autocomplete({
            container: containerRef.current,
            renderer: { createElement, Fragment, render },
            ...propsToAdd,
            plugins: pluginsToAdd,
            getSources: (params) => {
                if (!pluginsToConvert || pluginsToConvert.length === 0) {
                    return [];
                }
                const sources = pluginsToConvert.flatMap((plugin) =>
                    plugin.getSources(params)
                );
                return debounced(sources);
            },
            reshape: ({ sourcesBySourceId, sources, state }) => {
                const {
                    recentSearchesPlugin: recentSearches,
                    querySuggestionsPlugin: querySuggestions,
                    categoriesPlugin: categories,
                    popularPlugin: popular,
                    popularCategoriesPlugin: popularCategories,
                    ...rest
                } = sourcesBySourceId;

                const sourceIdsToExclude = [
                    "popularPlugin",
                    "popularCategoriesPlugin",
                ];
                const shouldDisplayPopularCategories = sources.every(
                    (source) => {
                        if (
                            sourceIdsToExclude.indexOf(source.sourceId) !== -1
                        ) {
                            return true;
                        }
                        return source.getItems().length === 0;
                    }
                );

                return [
                    combine(recentSearches, querySuggestions, categories),
                    [
                        !state.query && popular,
                        ...Object.values(rest),
                        shouldDisplayPopularCategories && popularCategories,
                    ].filter(Boolean),
                ];
            },
            render: ({ elements, state, Fragment }, root) => {
                const {
                    recentSearchesPlugin: recentSearches,
                    querySuggestionsPlugin: querySuggestions,
                    categoriesPlugin: categories,
                    productsPlugin: products,
                    popularPlugin: popular,
                    popularCategoriesPlugin: popularCategories,
                } = elements;

                const sourceIdsToExclude = [
                    "popularPlugin",
                    "popularCategoriesPlugin",
                ];
                const hasResults =
                    state.collections
                        .filter(
                            ({ source }) =>
                                sourceIdsToExclude.indexOf(source.sourceId) ===
                                -1
                        )
                        .reduce((prev, curr) => prev + curr.items.length, 0) >
                    0;

                render(
                    <div className="aa-PanelLayout aa-Panel--scrollable">
                        <div className="aa-PanelSections">
                            <div className="aa-PanelSection--left">
                                {hasResults
                                    ? (!state.query && recentSearches && (
                                          <Fragment>{recentSearches}</Fragment>
                                      )) ||
                                      (state.query && (
                                          <Fragment>
                                              <div className="aa-PanelSectionSources">
                                                  {recentSearches}
                                                  {querySuggestions}
                                                  {categories}
                                              </div>
                                          </Fragment>
                                      ))
                                    : state.query && (
                                          <>
                                              <div className="aa-NoResultsQuery">
                                                  No results for {state.query}.
                                              </div>
                                              <div className="aa-NoResultsAdvices">
                                                  <ul className="aa-NoResultsAdvicesList">
                                                      <li>
                                                          Double-check your
                                                          spelling
                                                      </li>
                                                      <li>
                                                          Use fewer keywords
                                                      </li>
                                                      <li>
                                                          Search for a less
                                                          specific item
                                                      </li>
                                                  </ul>
                                              </div>
                                          </>
                                      )}
                                {!state.query && (
                                    <div className="aa-PanelSection--popular">
                                        {popular}
                                    </div>
                                )}
                            </div>
                            <div className="aa-PanelSection--right">
                                {products && (
                                    <div className="aa-PanelSection--products">
                                        <div className="aa-PanelSectionSource">
                                            {products}
                                        </div>
                                    </div>
                                )}
                                {!hasResults && (
                                    <div
                                        className={cx(
                                            "aa-PanelSection--popularCategories",
                                            hasSourceActiveItem(
                                                "popularCategoriesPlugin",
                                                state
                                            ) && "aa-PanelSection--active"
                                        )}
                                    >
                                        {popularCategories}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>,
                    root
                );
            },
        });

        return () => {
            search.destroy();
        };
    }, [props.plugins]);

    return <div ref={containerRef} />;
}
